import React, { useState } from 'react';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import './styles/TopMenu.css';
import KeyBindingsModal from './components/KeyBindingsModal';
import { getAresHost } from '../constants';


const TopMenu = ({ game, controls, setControls, volumes, playerID }) => {

	const [showControls, setShowControls] = useState(false);

	const handleOpenControls = () => {
		setShowControls(true);
	};

	const handleCloseControls = () => {
		setShowControls(false);
	};

	const [devmode, setDevmode] = useState(false);

	const changeDevmodeBox = () => {
		setDevmode(!devmode);
		if (!devmode) {
			game.scene.keys.GameScene.scene.launch('DevHUD');
			game.scene.keys.GameScene.ShowCols();
		} else {
			game.scene.keys.DevHUD.scene.stop();
			game.scene.keys.GameScene.HideCols();
		}
	}

	function restartServer() {
		console.log("sending server halt message");
		fetch(`${getAresHost(game.scene.keys.GameScene.area)}/halt`);
	}

	return (
		<Navbar variant="dark" expand="lg">
			<Navbar.Brand href="#home">Primus</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<Dropdown>
						<Dropdown.Toggle variant="dark" id="dropdown-basic">
							dev
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={changeDevmodeBox}>toggle HUD</Dropdown.Item>
							<Dropdown.Item onClick={restartServer}>restart server</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>

					<button onClick={handleOpenControls}>Set Controls</button>
					{showControls &&
						<KeyBindingsModal
							onClose={handleCloseControls}
							controls={controls}
							setControls={setControls}
							game={game}
							volumes={volumes}
						>
						</KeyBindingsModal>
					}
					{/* Add as many dropdowns as you need */}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default TopMenu;
