import React from 'react';
import { Container } from 'react-bootstrap';
import './Landing.css';

import SignIn from './SignIn';
import SignOut from './SignOut';

function User({auth, user, setUser}) {
	var signInBits = "";
	if (user === null) {
		signInBits = (
			<SignIn auth={auth} setUser={setUser}/>
		)
	}

	var signOutBits = "";
	if (user !== null) {
		signOutBits = (
			<SignOut auth={auth} user={user} setUser={setUser}/>
		)
	}

	document.title = "primus"
	return (
		<div>
			<Container fluid>
				{signInBits}
				{signOutBits}
			</Container>
		</div>
	);
}

export default User;
