class SoundEffect {
	sounds;
	constructor() {
		this.sounds = [];
	}

	add(sound) {
		this.sounds.push(sound);
	}

	play(volume) {
		const i = Math.floor(Math.random() * this.sounds.length);
		this.sounds[i].setVolume(volume);
		this.sounds[i].play();
	}
}

class EffectsLoader {
	load;
	effects;
	volume;

	constructor(load) {
		this.load = load;
		this.effectKeys = {};
		this.effects = {};
		this.volume = 1;
	}

	loadSoundEffects(cfg) {
		var se = this;
		const key = `effects-${cfg}`;
		this.load.json(key, cfg)
		this.load.once(
			`filecomplete-json-${key}`,
			function (key, type, data) {
				const effectName = data.name;
				const keys = []
				data.files.forEach((file) => {
					const effectKey = `${data.dir.replaceAll('/', '_')}-${file.replaceAll('/', '_')}`;
					const effectPath = `${data.dir}/${file}`;
					se.load.audio(effectKey, effectPath);
					keys.push(effectKey);
				});
				if (effectName in se.effectKeys) {
					se.effectKeys[effectName] = se.effectKeys[effectName] + keys;
				} else {
					se.effectKeys[effectName] = keys;
				}
			},
		);
		this.load.start();
	}

	addSoundEffect(sound, name) {
		const seks = this.effectKeys[name];
		const se = new SoundEffect();
		seks.forEach((sek) => {
			se.add(sound.add(sek));
		});
		return se;
	}

	addAllEffects(sound) {
		for (const key in this.effectKeys) {
			const se = this.addSoundEffect(sound, key);
			this.effects[key] = se;
		}
	}

	setVolume(volume) {
		this.volume = volume;
	}

	play(key) {
		const fx = this.effects[key];
		if (fx === undefined) {
			return;
		}
		fx.play(this.volume);
	}
}

export { EffectsLoader, SoundEffect };