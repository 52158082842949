import React from 'react';
import { Row, Col } from 'react-bootstrap';

function AttrView({ attr }) {
	return (
		<div>{attr?.value} ({attr?.experience})</div>
	)
}

function MobSkillsView({ mob }) {
	return (
		<Row>
			<Col md={6}>
				<Row>
					<table>
						<tbody>
							<tr>
								<td>Health</td>
								<td><AttrView attr={mob?.attrs?.health} /></td>
							</tr>
							<tr>
								<td>Armor Points</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Evasion</td>
								<td><AttrView attr={mob?.attrs?.evasion} /></td>
							</tr>
						</tbody>
					</table>
				</Row>
			</Col>
			<Col md={6}>
				<Row>
					<table>
						<tbody>
							{mob?.attrs?.accuracies?.forEach((acc) => {
								<tr>
									<td>{acc?.type}</td>
									<td><AttrView attr={acc?.attr} /></td>
								</tr>
							})}
						</tbody>
					</table>
				</Row>
			</Col>
		</Row>
	)
}

export default MobSkillsView;