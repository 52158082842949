import React from 'react';

function EquipmentView({ mob, game }) {

	var head_acc = 0;
	var arms_acc = 0;
	var body_acc = 0;
	var legs_acc = 0;

	mob?.attrs?.accuracies?.forEach((acc) => {

		switch (acc?.type) {
			case mob?.equip_head?.type:
				head_acc = acc?.attr?.value;
				break;
			case mob?.equip_arms?.type:
				arms_acc = acc?.attr?.value;
				break;
			case mob?.equip_body?.type:
				body_acc = acc?.attr?.value;
				break;
			case mob?.equip_legs?.type:
				legs_acc = acc?.attr?.value;
				break;
		}
	});

	return (
		<div>
			<table>
				<thead>
					<tr>
						<th>Slot</th>
						<th>Acc</th>
						<th>Item</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Head</td>
						<td>{head_acc}</td>
						<td>{mob?.equip_head?.name}</td>
						<td>
							<button onClick={()=>{
								game.scene.keys.GameScene.unequipItem(mob, mob?.equip_head)
							}}
							className="items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-blue-600 hover:bg-blue-500 h-10 px-4 py-2 w-full">
								unequip
							</button>
						</td>
					</tr>
					<tr>
						<td>Arms</td>
						<td>{arms_acc}</td>
						<td>{mob?.equip_arms?.name}</td>
						<td>
							<button onClick={()=>{
								game.scene.keys.GameScene.unequipItem(mob, mob?.equip_arms)
							}} className="items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-blue-600 hover:bg-blue-500 h-10 px-4 py-2 w-full">
								unequip
							</button>
						</td>
					</tr>
					<tr>
						<td>Body</td>
						<td>{body_acc}</td>
						<td>{mob?.equip_body?.name}</td>
						<td>
							<button onClick={()=>{
								game.scene.keys.GameScene.unequipItem(mob, mob?.equip_body)
							}} className="items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-blue-600 hover:bg-blue-500 h-10 px-4 py-2 w-full">
								unequip
							</button>
						</td>
					</tr>
					<tr>
						<td>Legs</td>
						<td>{legs_acc}</td>
						<td>{mob?.equip_legs?.name}</td>
						<td>
							<button onClick={()=>{
								game.scene.keys.GameScene.unequipItem(mob, mob?.equip_legs)
							}} className="items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-blue-600 hover:bg-blue-500 h-10 px-4 py-2 w-full">
								unequip
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default EquipmentView;
