import React from 'react';
import { Row, Col } from 'react-bootstrap';
import StatsView from './StatsView';
import EquipmentView from './EquipmentView';
import InventoryView from './InventoryView';

function MobView({ mob, game }) {
	return (
		<div>
			<Row>
				<Col md={6}>
					<StatsView mob={mob} />
				</Col>
				<Col md={6}>
					<EquipmentView mob={mob} game={game} />
				</Col>
			</Row>
			<Row>
				<InventoryView mob={mob} game={game} />
			</Row>
		</div>
	);
}

export default MobView;
