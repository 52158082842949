import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Landing.css';
import { getMinervaHost } from '../../constants';

function PlayerPicker({user, playerID, setPlayerID}) {
	const navigate = useNavigate();

	const [availablePlayers, setAvailablePlayers] = useState([]);
	const [newPlayer, setNewPlayer] = useState("")

	async function fetchWithAccess(url, method, user) {
		try {
			const token = await user.getIdToken();
			const response = await fetch(url, {
				method: method,
				headers: {
					"Authorization": `Bearer ${token}`
				}
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			return response;
		} catch (err) {
			console.error('Error:', err);
			throw err; // Re-throw the error to handle it in the calling function
		}
	}

	function refreshPlayers() {
		if (user == null) {
			return;
		}
		const url = `${getMinervaHost()}/list/${encodeURI(user.email)}`;
		fetchWithAccess(url, "GET", user)
			.then(response => response.json())
			.then(players => {
				setAvailablePlayers(players.map((pi) => {return {pid: pi.player_id, area: pi.area};}));
			})
			.catch(err => {
				//TO DO: Add an option for the player to refetch
				//players with a refresh button in case of error
				console.error('Error fetching players:', err)
			});
	}

	useEffect(()=>{
		refreshPlayers()
	}, [user])

	function selectPlayer(selectedPID) {
		setPlayerID(selectedPID);
		// change CSS or something
	}

	function onChangeNewPlayer(evt) {
		setNewPlayer(evt.target.value);
	}

	function makeNewPlayer() {
		const url = `${getMinervaHost()}/create/${encodeURI(user.email)}/${newPlayer}`;
		fetchWithAccess(url, "POST", user).then(
			(response)=>{
				response.text().then((d)=>{
					console.log(d);
					refreshPlayers();
				})
			}).catch(console.log)
		// setPlayerID(newPlayer);
		// navigate('/play');
	}

	function playGame() {
		navigate('/play');
	}

	function playAs(pid){
		console.log(pid);
		selectPlayer(pid);
		playGame();
	}

	if (user == null) {
		return (
			<div></div>
		)
	}
	// .header, .microblog, .player-picker, .links, .create-player, .footer
	return (
		<div>
			<h1 className="text-3xl font-bold mb-4">Select Your Character</h1>
			<div className="flex-1 gap-4 p-4 md:gap-8 md:p-6 flex overflow-x-auto">
			{availablePlayers.map((player)=>{
				return (
				<div key={player.pid} className="w-96 rounded-lg border border-gray-600 bg-gray-700 shadow-sm">
					<div className="flex flex-col space-y-1.5 p-6 pb-4 w-96">
						<h3 className="text-2xl font-semibold leading-none tracking-tight">{player.pid}</h3>
						<p className="text-sm text-muted-foreground">Area: {player.area ?? "Unknown"}</p>
					</div>
					<div className="flex items-center p-6">
						<button onClick={() => {playAs(player.pid)}} className="items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-blue-600 hover:bg-blue-500 h-10 px-4 py-2 w-full">
							<span>Play</span>
						</button>
					</div>
				</div>
				)
			})}
			</div>
			<div className="rounded-lg border bg-card text-card-foreground shadow-sm mt-4">
				<div className="flex flex-col space-y-1.5 p-6 pb-4">
					<h3 className="text-2xl font-semibold leading-none tracking-tight">Create New Character</h3>
				</div>
				<div className="flex items-center space-x-4 p-6">
					<input
						type="text"
						className="mb-0"
						placeholder="New Character Name..."
						value={newPlayer}
						onChange={onChangeNewPlayer}
						onKeyDown={(evt) => {
							if (evt.key === 'Enter') {
								makeNewPlayer();
							}
						}}
					/>
					<button onClick={makeNewPlayer} className="rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-blue-600 hover:bg-blue-500 px-4">
						Create
					</button>
				</div>
			</div>
		</div>
	);
}

export default PlayerPicker;
