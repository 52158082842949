import React from 'react';

function VolumeSlider({ value, setValue }) {

	function onValueChange(event) {
		setValue(event.target.valueAsNumber);
	}

	return (
		<input
			type="range"
			min={0}
			max={1}
			step={0.05}
			value={value}
			onChange={onValueChange}
		/>
	);
}

export default VolumeSlider;
