import Phaser from 'phaser';
import gameConfig from './gameConfig';
import Intro from './scenes/Intro';
import Loading from './scenes/Loading';
import GameScene from './scenes/GameScene';
import CommandInput from './scenes/CommandInput';
import DevHUD from './scenes/DevHUD';

export default function PhaserGame(domNode, user, playerID) {
	console.log(`Creating new PhaserGame for ${domNode}, ${user}, ${playerID}`)
	return new Phaser.Game({
		...gameConfig,
		parent: domNode,
		scene: [Loading, Intro, GameScene, DevHUD, CommandInput]
	});
}
