export const DEFAULT_CONTROLS = {
	north: 'w',
	west: 'a',
	south: 's',
	east: 'd',
	head: 'one',
	arms: 'two',
	body: 'three',
	legs: 'four',
	console: 'backtick',
	interact: 'space',
};

export function getPyodide() {
	return "https://cdn.jsdelivr.net/pyodide/v0.23.4/full/";
}

export function getThonkingWheel(area) {
	return getAresHost(area) + "/p/thonking-0.1.2-py3-none-any.whl";
}

export function getAresHostNoProtocol(area) {
	switch (getEnv()) {
		case "local":
			switch (area) {
				case "town":
					return "localhost:8080";
				case "castle":
					return "localhost:8081";
				case "tutorial":
					return "localhost:8082";
				case "crasemer":
					return "localhost:8083";
				default:
					return undefined;
			}
	case "autopush":
			return "ares-autopush.primus-game.com";
	case "qa":
			return "ares-qa.primus-game.com/AREA".replace("AREA", area);
		default:
			return undefined;
	}
}

export function getAresHost(area) {
	switch (getEnv()) {
		case "local":
			return "http://" + getAresHostNoProtocol(area);
	case "autopush":
			return "https://" + getAresHostNoProtocol(area);
	case "qa":
			return "https://" + getAresHostNoProtocol(area);
		default:
			return undefined;
	}
}

export function getMinervaHost() {
	switch (getEnv()) {
		case "local":
			return "http://localhost:9080";
		case "autopush":
			return "https://minerva-autopush.primus-game.com";
		case "qa":
			return "https://minerva-qa.primus-game.com";
		default:
			return undefined;
	}
}

export function getAresTilemaps(area) {
	return getAresHost(area) + "/t";
}

export function getAresWS(area) {
	switch (getEnv()) {
		case "local":
			return "ws://" + getAresHostNoProtocol(area) + "/ws";
	case "autopush":
			return "wss://" + getAresHostNoProtocol(area) + "/ws";
	case "qa":
			return "wss://" + getAresHostNoProtocol(area) + "/ws";
		default:
			return undefined;
	}
}

function getEnv() {
	if (window.location.href.startsWith("http://localhost")) {
		return "local";
	}
	if (window.location.href.startsWith("https://autopush.primus-game.com")) {
		return "autopush";
	}
	if (window.location.href.startsWith("https://qa.primus-game.com")) {
		return "qa";
	}
	return undefined;
}

export const TILE_WIDTH = 32;
export const BASE_SPEED = 4 * TILE_WIDTH;
export const COOLDOWN_COLOR = "0xdecd16";
