import Phaser from 'phaser';
import Controller from '../../primus/control/Controller.js';
import { LoadSprites, LoadTiles, AddTiledJSON, MakeLayers } from './Sprites'
import MobSet from '../../primus/mobs/MobSet';
import { EffectsLoader } from '../audio/Effects.js';
import { getAresTilemaps, getAresWS } from '../../constants.js';
import { storedTank } from '../../primus/thonking/thonktank';
import SpriteLoader from '../sprites/SpriteLoader.js';

var primuspb = require('../../primus/primuspb/primus_pb.js')

class GameScene extends Phaser.Scene {
	constructor() {
		super("GameScene");
		this.created = false;
	}

	gameHooks;
	baseHost;
	secureSocket;

	targettedMob;

	tilemap_alias;
	assetBaseURL;
	controller;
	ws_connected;
	ws;
	tilemap;
	tilemapLayers;
	tilemap_config;
	mobs;
	soundEffects;
	inConsole;
	thonkDuration;
	spriteLoader;

	preload() {
		this.soundEffects = new EffectsLoader(this.load);
		this.soundEffects.loadSoundEffects("assets/sounds/eating/manifest.json");
		this.soundEffects.loadSoundEffects("assets/sounds/punching/manifest.json");
		this.soundEffects.loadSoundEffects("assets/sounds/dying/manifest.json");
		const gs = this;
		gs.load.on('complete', () => {
			gs.soundEffects.addAllEffects(gs.sound);
		});
		this.spriteLoader = new SpriteLoader(this.load, this.cache, this.anims);
		// TODO: specify this list in a config file somewhere.
		this.spriteLoader.fromConfig("deco/target");
		this.spriteLoader.fromConfig("deco/skull");
		this.spriteLoader.fromConfig("effects/bite");
		this.spriteLoader.fromConfig("effects/slash");
		this.spriteLoader.fromConfig("effects/fist");
	}

	async openWebsocket(playerID) {
		const gs = this;
		const token = await gs.gameHooks.user.getIdToken();
		this.ws = new WebSocket(
			getAresWS(gs.gameHooks.area)
		);

		this.ws.onopen = function (evt) {
			var init = new primuspb.Init();
			init.setPlayerId(playerID);
			init.setAccessToken(token);
			init.setAccount(gs.gameHooks.user.email);
			try {
				gs.ws.send(init.serializeBinary());
			} catch (error) {
				console.log("websocket error in onopen");
				console.dir(error);
			}
			gs.ws_connected = true;
			gs.last_connection = Date.now();
		}
		this.ws.onclose = function (evt) {
			if (gs.ws_connected === false) {
				return;
			}
			if (Date.now() - gs.last_connection < 1000) {
				gs.scene?.stop();
				return;
			}
			gs.ws_connected = false;
			gs.scene?.restart();
		}
		this.ws.onerror = function (evt) {
			console.log("websocket error");
			console.dir(evt);
		}
		this.ws.onmessage = function (evt) {
			evt.data.arrayBuffer().then(result => {
				var frameMsg = primuspb.Frame.deserializeBinary(result);

				const area = frameMsg.getReloadArea();

				if (area !== null && area !== "") {
					// Signal that the disconnect doesn't go back to intro.
					console.log(`got a reload, waiting for ${area} != ${gs.gameHooks.area}`)
					const game = gs.game;
					const gameHooks = gs.gameHooks;
					const setGameHooks = gs.setGameHooks;
					gs.ws.close();
					gs.scene.remove();

					setGameHooks({
						...gameHooks,
						area: area,
					});

					const nextScene = game.scene.add("GameScene", GameScene, false);
					nextScene.trackHooks(gameHooks, setGameHooks);

					game.scene.keys.Intro.scene.stop();
					game.scene.keys.Intro.scene.launch("GameScene");


					return;
				}

				// We do this in a brief block here, rather than in more structured code,
				// because in the client the tilemap is totally cosmetic. All interaction
				// with tiles happens server-side.
				if (frameMsg.getTilemap() != null) {
					var tm = frameMsg.getTilemap();
					if (gs.tilemap_config == null || tm.getAlias() !== gs.tilemap_config.getAlias()) {
						storedTank.tank.loadTilemap(tm);
						gs.tilemap_alias = tm.getAlias();
						gs.tilemap_config = tm

						// We remove this for two reasons.
						// 1 - if the tilemap has changed (server restart), let's
						//		 get the new version.
						// 2 - it simplifies the logic below - if it's already in the
						//		 cache there will be no "complete" message to hear.
						gs.cache.tilemap.remove(`${tm.getAlias()}_json`);

						LoadTiles(gs.load, tm, gs.assetBaseURL);
						// We have to start the loader...
						gs.load.start()

						// And async wait for it to complete before adding the tilemap to the scene.
						gs.load.on('complete', () => {
							gs.tilemap = AddTiledJSON(gs.make, tm)
							gs.tilemapLayers = MakeLayers(gs.tilemap);
						});
					}
				}

				gs.mobs.applyFrame(frameMsg);
				// Delay the clearing to give people a chance to release
				// a key when a movement begins, and have it not be in
				// effect for the next turn.
				setTimeout(function () { gs.controller.clear() }, 150);

				const thonkStart = Date.now();
				gs.mobs.thonk(frameMsg, (act) => {
					if (!gs.ws_connected) {
						return;
					}
					gs.ws.send(act.serializeBinary());
				})
				gs.thonkDuration = Date.now() - thonkStart;
				gs.gameHooks.setTickCount(frameMsg.getNumber());
			});
		}
	}

	create() {
		console.dir(this.gameHooks);
		console.log(`create with ${this.gameHooks.area}`);

		this.created = true;
		this.inConsole = false;

		this.cameras.main.setZoom(1);
		this.mobs = new MobSet(this.gameHooks.playerID, this, this.cameras.main, this.spriteLoader);

		this.controller = new Controller();
		this.controllerEnabled = false;

		this.ws_connected = false;
		this.openWebsocket(this.gameHooks.playerID);

		this.assetBaseURL = getAresTilemaps(this.gameHooks.area);

		this.input.on('gameobjectdown', this.onObjectClicked);
		this.input.on('gameout', (pointer) => {
			this.game.input.keyboard.enabled = false;
		});
		this.input.on('gameover', (pointer) => {
			if (!this.inConsole) {
				this.addKeys();
			}
			this.game.input.keyboard.enabled = true;
		});
		this.addKeys();
	}

	update(time) {
		var focus = this.mobs.getFocus();
		if (focus == null) {
			return;
		}

		if (this.game.input.keyboard.enabled && this.controller.matchCursors(this.cursors)) {
			if (this.ws_connected) {
				var act = this.controller.action();
				act.setMobId(focus.id);
				if (this.targettedMob != null) {
					act.setTargetId(this.targettedMob.id);
				}
				this.ws.send(act.serializeBinary());
			}
		}

		this.mobs.updateDecorations();
		this.mobs.updateEffectAnimations(time);

		if (!this.inConsole && this.cursors.console.isDown) {
			this.openConsole();
		}
	}

	target(mob) {
		this.targettedMob = mob;
		this.gameHooks.setTargetMob(mob);
		this.mobs.setTarget(mob);
	}

	untarget(mob) {
		if (mob !== undefined && mob !== this.targettedMob) {
			return;
		}
		this.targettedMob = null;
		this.gameHooks.setTargetMob(null);
		this.mobs.setTarget(null);
	}

	onObjectClicked(pointer, gob) {
		const gs = this.scene;
		if (gs.inConsole) {
			gs.scene.manager.keys.CommandInput.clickWrite(gob.mob.id);
		}
		if (gs.gameHooks.setTargetMob === null) {
			return;
		}
		if (gs.targettedMob === gob.mob) {
			return;
		}
		gs.target(gob.mob)
	}

	openConsole() {
		this.inConsole = true;
		this.input.keyboard.removeAllKeys();
		this.scene.launch('CommandInput');
		this.scene.bringToTop('CommandInput');
	}

	closeConsole() {
		this.inConsole = false;
		this.addKeys();
	}

	sendCommand(text) {
		const focus = this.mobs.getFocus();
		if (focus == null) {
			console.log("could not send command because no mob in focus")
			return;
		}
		if (this.targettedMob != null) {
			text = text.replace("$", this.targettedMob.id);
		}

		const a = new primuspb.Action();
		a.setCommand(text);
		a.setMobId(focus.id);
		console.log(`sending command: ${text}`);
		this.ws.send(a.serializeBinary());
	}

	addKeys() {
		this.input.keyboard.removeAllKeys();
		const gs = this;
		this.input.keyboard.on(Phaser.Input.Keyboard.Events.ANY_KEY_DOWN, (event) => {
			if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.ESC) {
				gs.untarget();
				return;
			}
		})
		this.cursors = this.input.keyboard.addKeys(this.gameHooks.controls);
	}

	setControls(controls) {
		this.setGameHooks({
			...this.gameHooks,
			controls: controls	
		})
		if (this.created && !this.inConsole) {
			this.addKeys();
		}
	}

	ShowCols() {
		this.tilemapLayers["collision"].setVisible(true);
	}

	HideCols() {
		this.tilemapLayers["collision"].setVisible(false);
	}

	trackHooks(gameHooks, setGameHooks) {
		this.gameHooks = gameHooks;
		this.setGameHooks = setGameHooks;
	}

	equipItem(mob, item) {
		if (mob === undefined || item === undefined) {
			return;
		}
		console.log(`${mob.id} to equip ${item.id}`);
		
		const a = new primuspb.Action();
		a.setEquipId(item.id);
		a.setMobId(mob.id);
		this.ws.send(a.serializeBinary());
	}

	unequipItem(mob, item) {
		if (mob === undefined || item === undefined) {
			return;
		}
		console.log(`${mob.id} to unequip ${item.id}`);
		const a = new primuspb.Action();
		a.setRemove(item.slot);
		a.setMobId(mob.id);
		this.ws.send(a.serializeBinary());
	}

	discardItem(mob, item) {
		if (mob === undefined || item === undefined) {
			return;
		}
		console.log(`${mob.id} to discard ${item.id}`);
		const a = new primuspb.Action();
		a.setDiscardId(item.id);
		a.setMobId(mob.id);
		this.ws.send(a.serializeBinary());
	}
}

export default GameScene;
