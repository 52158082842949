var primuspb = require('../../primus/primuspb/primus_pb.js')

class Controller {
	constructor() {
		this.clear();
	}

	clone() {
		var c = new Controller();
		c.n = this.n;
		c.e = this.e;
		c.s = this.s;
		c.w = this.w;
		c.head = this.head;
		c.body = this.body;
		c.arms = this.arms;
		c.legs = this.legs;
		return c;
	}

	matchCursors(cursors) {
		var oldControls = this.clone();
		this.n |= cursors.north.isDown;
		this.e |= cursors.east.isDown;
		this.s |= cursors.south.isDown;
		this.w |= cursors.west.isDown;
		this.head |= cursors.head.isDown;
		this.body |= cursors.body.isDown;
		this.arms |= cursors.arms.isDown;
		this.legs |= cursors.legs.isDown;
		this.interact |= cursors.interact.isDown;
		return !this.equals(oldControls);
	}

	equals(c) {
		// eslint-disable-next-line
		return this.n === c.n
			&& this.e === c.e
			&& this.s === c.s
			&& this.w === c.w
			&& this.head === c.head
			&& this.body === c.body
			&& this.arms === c.arms
			&& this.legs === c.legs
			&& this.interact === c.interact;
	}

	clear() {
		this.n = false;
		this.e = false;
		this.s = false;
		this.w = false;
		this.head = false;
		this.body = false;
		this.arms = false;
		this.legs = false;
		this.interact = false;
	}

	action() {
		var a = new primuspb.Action();
		// the order of checks in this function implies a priority
		// for different types of actions.
		if (this.n) {
			a.setMovement(primuspb.Direction.DIR_NORTH);
		}
		if (this.e) {
			a.setMovement(primuspb.Direction.DIR_EAST);
		}
		if (this.s) {
			a.setMovement(primuspb.Direction.DIR_SOUTH);
		}
		if (this.w) {
			a.setMovement(primuspb.Direction.DIR_WEST);
		}
		if (this.head) {
			a.setUse(primuspb.Slot.SLOT_HEAD);
		}
		if (this.body) {
			a.setUse(primuspb.Slot.SLOT_BODY);
		}
		if (this.arms) {
			a.setUse(primuspb.Slot.SLOT_ARMS);
		}
		if (this.legs) {
			a.setUse(primuspb.Slot.SLOT_LEGS);
		}
		if (this.interact) {
			a.setUse(primuspb.Slot.SLOT_INTERACT);
		}
		return a;
	}
}

export default Controller;
