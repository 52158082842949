import Phaser from 'phaser';

export default class IntroScene extends Phaser.Scene {
	music;

	constructor() {
		super('Intro');
		this.setPlayerIDDisabled = null;
		this.music = null;
	}

	setMusic(music) {
		this.music = music;
		this.music.loadAndPlay(this);
	}

	setMusicVolume(vol) {
		this.music.setVolume(vol);
	}

	create() {
		if (this.setPlayerIDDisabled != null) {
			this.setPlayerIDDisabled(false);
		}
		this.add.text(100, 100, "enter player id to begin");
	}
}
