import { TILE_WIDTH } from "../../constants";

class Mob {
	id;
	controllerID;
	spriteset;
	sprite;
	decorations;
	x;
	y;
	msg;
	healthbar;
	entity;

	constructor(msg) {
		this.msg = msg;
		this.id = msg.getId();
		this.controllerID = msg.getControllerId();
		this.spriteset = msg.getSpriteAsset();
		this.decorations = [];
		this.type = msg.getType();
		this.x = msg.getX();
		this.y = msg.getY();
		this.healthbar = null;
		this.skull = null;

		if (this.spriteset === "") {
			console.error(`mob ${this.id} has no sprite_asset`);
		}
	}

	move(x, y) {
		this.x = x;
		this.y = y;
	}

	remove() {
		this.entity?.destroy();
		this.decorations.forEach(([spr, ox, oy]) => {
			spr.destroy();
		})
	}

	addDecoration(spr, ox, oy) {
		this.decorations.push([spr, ox, oy]);
		return spr;
	}

	addIDDecor(scene) {
		const w = TILE_WIDTH;
		const name = this.msg?.getName() ?? null;
		if (name === null) {
			return;
		}
		var idLabel = scene.add.text(0, 0, name, {
			fontSize: 12,
		})
		idLabel.depth = 48;

		return this.addDecoration(idLabel, -w / 2, -w);
	}

	addHealthbar(scene) {
		const w = TILE_WIDTH;
		this.healthbar = scene.add.rectangle(0, 0, w, 2, "0xFF0000", 1);
		this.healthbar.depth = 49;
		return this.addDecoration(this.healthbar, 0, -(2 + w / 2));
	}

	async addTargetSprite(scene, spriteLoader) {
		const alias = await spriteLoader.fromConfig("deco/target")
		const target = spriteLoader.add(scene, alias);
		target.anims.play(`${alias}:pulse`, true);
		return this.addDecoration(target, 0, 0);
	}

	setSkull(scene, spriteLoader, skullon) {
		if (skullon) {
			if (this.skull != null) {
				return;
			}
			spriteLoader.fromConfig("deco/skull").then((alias) => {
				this.skull = spriteLoader.add(scene, alias);
				this.addDecoration(this.skull, 0, 0);
				this.skull.depth = 2;
			})
			scene.soundEffects.play("death");
		} else {
			if (this.skull == null) {
				return;
			}
			this.removeDecoration(this.skull);
			this.skull.destroy();
			this.skull = null;
		}
	}

	removeDecoration(spr) {
		for (var i = 0; i < this.decorations.length; i++) {
			if (this.decorations[i] === spr) {
				this.decorations.splice(i, 1);
				return;
			}
		}
	}

	updateDecorations() {
		if (this.msg === null) {
			return
		}
		if (this.healthbar != null) {
			var health = this?.msg?.getAttrs()?.getHealth()?.getValue();
			var hitpoints = this?.msg?.getAttrs()?.getHitPoints();

			if (hitpoints < 0 || hitpoints === undefined) {
				hitpoints = 0;
			}
			if (health === undefined) {
				health = 0;
			}

			const ratio = hitpoints / health;
			if (ratio > 1) {
				ratio = 0;
			}
			this.healthbar.scaleX = ratio;
		}

		const x = this.entity.c.x;
		const y = this.entity.c.y;

		this.decorations.forEach(([spr, ox, oy]) => {
			spr.setPosition(x + ox, y + oy);
		})
	}
}

export default Mob;
