
export function LoadTiles(load, tilemap_config, baseURL) {
	const alias = tilemap_config.getAlias();
	const tiled_config = tilemap_config.getTiledConfig();

	const tilemap_config_path = `${baseURL}/${tiled_config}?${Math.random()}`;

	console.log(`Loading tilemap json ${tilemap_config_path}`);
	load.tilemapTiledJSON(alias + "_json", `${tilemap_config_path}`);

	const asset_base = `${baseURL}/tilemaps`
	load.once(
		`filecomplete-tilemapJSON-${alias}_json`,
		function (key, type, data) {
			load.setPath(asset_base);
			for (var tileset of data.tilesets) {
				const image_path = `${tileset.image}?${Math.random()}`;
				load.image(tileset.name, image_path);
			}
		},
	);
}

export function AddTiledJSON(make, tilemap_config) {
	const alias = tilemap_config.getAlias();
	const m = make.tilemap({ key: alias + "_json" });

	for (var tileset of m.tilesets) {
		m.addTilesetImage(tileset.name);
	}

	return m;
}

export function MakeLayers(m) {
	var layers = {};
	for (var layerData of m.layers) {
		var l = m.createLayer(layerData.name, m.tilesets);
		l.setDepth(-1);
		if (layerData.name === "collision") {
			l.setVisible(false);
		}
		if (layerData.name === "floating") {
			l.setDepth(10);
		}
		layerData.properties.forEach(prop => {
			if (prop.name == "depth") {
				l.setDepth(prop.value);
			}
		});
		layers[layerData.name] = l;
	}
	return layers;
}
