import React, { useEffect, useState, useRef } from 'react';
import KeyBindingRow from './KeyBindingRow';
import { DEFAULT_CONTROLS } from '../../constants';	// Importing default controls
import VolumeSlider from './VolumeSlider';

const KeyBindingsModal = ({ onClose, controls, setControls, game, volumes }) => {
	const [tempControls, setTempControls] = useState(controls);
	const { musicVolume, setMusicVolume, fxVolume, setFxVolume } = volumes;

	const prevMusicVolume = useRef(musicVolume);
	useEffect(() => {
		prevMusicVolume.current = musicVolume;
	});
	const prevFxVolume = useRef(fxVolume);
	useEffect(() => {
		prevFxVolume.current = fxVolume;
	});

	const handleSave = () => {
		setControls(tempControls);
		onClose();
	};

	const handleDiscard = () => {
		handleReset();
		onClose();
	};

	const handleReset = () => {
		setTempControls(DEFAULT_CONTROLS);
		setMusicVolume(prevMusicVolume.current);
		setFxVolume(prevFxVolume.current);
	};

	return (
		<div width="1000px" className="modal-backdrop custom-modal">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Set Key Bindings</h5>
					</div>
					<div className="overflow-y-auto">
						<div className="grid grid-cols-2">
							<div className="modal-body space-y-3">
								{Object.keys(tempControls).map(key => (
									<KeyBindingRow
										key={key}
										controlName={key}
										controlValue={tempControls[key]}
										setControls={setTempControls}
										controls={tempControls}
									/>
								))}
							</div>
							<div className="modal-body space-y-4 justify-center flex flex-col">
								<div className="grid grid-cols-2 items-center space-x-2">
									<span>Music</span>
									<VolumeSlider value={musicVolume} setValue={setMusicVolume} />
								</div>
								<div className="grid grid-cols-2 items-center space-x-2">
									<span>SFX</span>
									<VolumeSlider value={fxVolume} setValue={setFxVolume} />
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer keybindings-footer space-x-4">
						<button type="button" className="border rounded bg-gray-900 text-white hover:bg-gray-800 border border-gray-700" onClick={handleDiscard}>
							Discard
						</button>
						<button type="button" className="border rounded bg-red-800 text-white hover:bg-red-700 border border-red-600" onClick={handleReset}>
							Reset
						</button>
						<button type="button" className="border rounded bg-blue-600 text-white hover:bg-blue-500 border border-blue-700" onClick={handleSave}>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default KeyBindingsModal;
