import React, { useState } from 'react';
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
} from 'firebase/auth';

function SignIn({ auth, setUser }) {

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	function onChangeEmail(event) {
		setEmail(event.target.value);
	}
	function onChangePassword(event) {
		setPassword(event.target.value);
	}
	function signIn() {
		signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			setEmail("");
			setPassword("");
			setUser(userCredential.user);
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			console.log(`sign-in error: ${errorCode} ${errorMessage}`);
		});
	}
	function signUp() {
		createUserWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			setUser(userCredential.user);
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			console.log(`sign-up error: ${errorCode} ${errorMessage}`);
		});
	}

	return (
		<div className="flex items-center justify-center">
			<div className="rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="font-semibold tracking-tight text-2xl border-b border-slate-500">Login</h3>
					<p className="text-sm text-muted-foreground">Enter your username and password to login to your account.</p>
				</div>
				<div className="p-6 space-y-4">
					<div className="space-y-2">
						<label
							className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
							htmlFor="email"
						>
							Email
						</label>
						<input
							className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
							type="text"
							placeholder="email"
							value={email}
							onChange={onChangeEmail}
							onKeyDown={(evt) => {
								if (evt.key === 'Enter') {
									signIn();
							}
							}}
						/>
					</div>
					<div className="space-y-2">
						<label
							className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
							htmlFor="password"
						>
							Password
						</label>
						<input
							className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
							type="password"
							placeholder="password"
							value={password}
							onChange={onChangePassword}
							onKeyDown={(evt) => {
								if (evt.key === 'Enter') {
									signIn();
								}
							}}
						/>
					</div>
				</div>
				<div className="flex items-center p-6 space-x-4">
					<button onClick={signIn} className="rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-blue-600 hover:bg-blue-500 h-10 px-4 py-2 w-full">
						Login
					</button>
					<button onClick={signUp} className="rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 border h-10 px-4 py-2 w-full">
						Sign Up
					</button>
				</div>
			</div>
		</div>
	);
}

export default SignIn;
