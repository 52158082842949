export default class Music {
	baseURL;
	bgmusic;
	volume;

	constructor(baseURL, volume) {
		this.baseURL = baseURL;
		this.volume = volume;
	}

	loadAndPlay(scene) {
		const m = this;
		// scene.load.audio('overworld', [`${this.baseURL}/s/music/dw-overworld.mp3`]);
		scene.load.audio('drumcorder', [`${this.baseURL}/s/music/drumcorder.flac`]);
		scene.load.start()
		scene.load.once(
			`filecomplete-audio-drumcorder`,
			function (key, type, data) {
				m.bgmusic = scene.sound.add('drumcorder');
				m.bgmusic.loop = true;
				m.bgmusic.play();
				m.bgmusic.setVolume(m.volume);
			},
		);
	}

	setVolume(vol) {
		this.volume = vol;
		this.bgmusic?.setVolume(this.volume);
	}
}