import React from 'react';
import './Landing.css';

function InfoPanel() {
	return (
		//  .header, .microblog, .player-picker, .links, .create-player, .footer
		<div className="border border-slate-500 rounded">
			<h2 className="text-2xl font-bold text-gray-100 border-b border-slate-500 m-0 py-2">Recent Updates</h2>
			<div className="space-y-4 info-maxheight px-4 overflow-y-auto py-2">
				<div>
					<a className="text-lg font-semibold hover:underline text-blue-400" href="#" rel="ugc">
						Update 1.0.1
					</a>
					<p className="text-gray-400">
						Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Eu tincidunt tortor aliquam nulla facilisi cras. Tincidunt dui ut ornare lectus sit amet est. In iaculis nunc sed augue lacus viverra vitae. Duis ut diam quam nulla porttitor massa id neque aliquam. Placerat in egestas erat imperdiet sed euismod. Cras ornare arcu dui vivamus arcu felis bibendum ut. Viverra maecenas accumsan lacus vel facilisis volutpat. Urna molestie at elementum eu facilisis sed odio morbi. Et malesuada fames ac turpis egestas maecenas pharetra.
					</p>
				</div>
				<div>
					<a className="text-lg font-semibold hover:underline text-blue-400" href="#" rel="ugc">
						Update 1.0.2
					</a>
					<p className="text-gray-400">
						Nunc consequat interdum varius sit amet mattis. Vitae turpis massa sed elementum tempus. Turpis tincidunt id aliquet risus feugiat in ante metus. Purus semper eget duis at tellus at urna condimentum. Gravida arcu ac tortor dignissim convallis aenean et. At tellus at urna condimentum mattis pellentesque id. Enim diam vulputate ut pharetra. Et tortor at risus viverra adipiscing. Purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus. Ut porttitor leo a diam sollicitudin tempor id. At varius vel pharetra vel turpis nunc eget. Quis viverra nibh cras pulvinar mattis nunc sed.
					</p>
				</div>
				<div>
					<a className="text-lg font-semibold hover:underline text-blue-400" href="#" rel="ugc">
						Update 1.0.3
					</a>
					<p className="text-gray-400">
						Auctor neque vitae tempus quam pellentesque. Nibh venenatis cras sed felis eget. Lacus vestibulum sed arcu non odio. Faucibus vitae aliquet nec ullamcorper sit amet. Non curabitur gravida arcu ac. Tortor at risus viverra adipiscing at in. Id aliquet lectus proin nibh nisl condimentum. Neque sodales ut etiam sit amet nisl purus. Urna duis convallis convallis tellus. Lacus viverra vitae congue eu consequat ac. In est ante in nibh mauris cursus mattis molestie. Lobortis mattis aliquam faucibus purus in. Quis lectus nulla at volutpat diam ut. Suspendisse ultrices gravida dictum fusce ut. Sed tempus urna et pharetra pharetra massa massa. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Lacinia quis vel eros donec ac odio. Turpis nunc eget lorem dolor sed viverra.
					</p>
				</div>
			</div>
		</div>
	);
}

export default InfoPanel;
