import React, { useCallback } from 'react';

import PhaserGame from './PhaserGame';
import './styles/PhaserCanvas.css';

function PhaserCanvas({setGame, targetMob, setTargetMob, setTickCount, user, playerID}) {
	// eslint-disable-next-line
	const ref = useCallback(domNode => {
		if (domNode == null) {
			return undefined;
		}
		var pg = PhaserGame(domNode, user, playerID);
		setGame(pg);
		if (pg.scene.keys.GameScene !== undefined) {
			pg.scene.keys.GameScene.setTargetMob = setTargetMob;
			pg.scene.keys.GameScene.targetMob = targetMob;
			pg.scene.keys.GameScene.setTickCount = setTickCount;
		}
		return pg;
	}, [setGame, setTargetMob, setTickCount, user, playerID]);

	return <div ref={ref}></div>;
}

export default PhaserCanvas;
