import { COOLDOWN_COLOR, TILE_WIDTH } from "../../constants";

class EffectAnimation {
	sx;
	sy;
	duration;
	startTime;
	sprite;
	follows;
	constructor(sx, sy, duration) {
		this.sx = sx;
		this.sy = sy;
		this.duration = duration;
		this.startTime = undefined;
		this.vx = 0;
		this.vy = 0;
		this.fade = false;
		this.follows = null;
		this.offset_x = 0;
		this.offset_y = 0;
		this.follow_offset_x = 0;
		this.follow_offset_y = 0;
	}

	update(time) {
		if (this.sprite === undefined) {
			return;
		}
		if (this.startTime === undefined) {
			this.startTime = time;
		}
		const secondsPassed = (time - this.startTime) / 1000;
		this.offset_x = this.vx * secondsPassed;
		this.offset_y = this.vy * secondsPassed;
		this.sprite.x = this.sx + this.offset_x;
		this.sprite.y = this.sy + this.offset_y;

		if (this.fade) {
			const ratio = 1 - (secondsPassed / this.duration);
			this.sprite.alpha = ratio;
		}

		if (this.follows != null) {
			if (this.follows.entity !== undefined) {
				this.sprite.setPosition(
					this.follows.entity.c.x + this.follow_offset_x + this.offset_x,
					this.follows.entity.c.y + this.follow_offset_y + this.offset_y,
				);
			}
			else if (this.follows.sprite != null) {
				this.sprite.setPosition(
					this.follows.sprite.x + this.follow_offset_x + this.offset_x,
					this.follows.sprite.y + this.follow_offset_y + this.offset_y,
				);
			}
		}
	}

	expired(time) {
		if (this.startTime === undefined) {
			this.startTime = time;
		}
		const secondsPassed = (time - this.startTime) / 1000;
		return secondsPassed >= this.duration;
	}

	destroy() {
		this.sprite?.destroy();
	}
}

class CooldownEffect extends EffectAnimation {
	update(time) {
		super.update(time);
		const secondsPassed = (time - this.startTime) / 1000;
		const ratio = 1 - (secondsPassed / this.duration);
		this.sprite.scaleX = ratio;
	}
}

function createDamage(mobset, mobId, value) {
	return createFloatingNumber(mobset, mobId, value, 'red', 100, 30)
}

function createAbsorb(mobset, mobId, value) {
	return createFloatingNumber(mobset, mobId, value, 'yellow', 99, 20)
}

function createFloatingNumber(mobset, mobId, value, color, depth, fontSize) {
	const mob = mobset.getMob(mobId);
	if (mob === undefined) {
		return;
	}

	const x = mob.entity.c.x + (0.5-Math.random())*TILE_WIDTH*.5;
	const y = mob.entity.c.y + (0.5-Math.random())*TILE_WIDTH*.5;

	var e = new EffectAnimation(x, y, 1.5);
	e.sprite = mobset.scene.add.text(x, y, `${value}`, {
		fontSize: fontSize,
		color: color,
		stroke: color,
		strokeThickness: 2,
	})
	e.sprite.depth = depth;
	e.vy = -TILE_WIDTH;
	e.fade = true;

	return e;
}

function createCooldown(mobset, mobId, ticks) {
	const durationSeconds = ticks / 4;
	const mob = mobset.getMob(mobId);
	if (mob === undefined) {
		return;
	}
	const x = mob.entity.c.x;
	const y = mob.entity.c.y;
	const e = new CooldownEffect(x, y, durationSeconds);
	const cooldown = mobset.scene.add.rectangle(x, y, TILE_WIDTH, 2, COOLDOWN_COLOR, 1);
	e.sprite = cooldown;
	e.follows = mob;
	e.offset_x = 0;
	e.offset_y = -TILE_WIDTH / 2;
	e.follow_offset_x = 0;
	e.follow_offset_y = -TILE_WIDTH / 2;
	e.sprite.depth = 50;
	return e;
}

function createZap(mobset, mobId, alias) {
	const mob = mobset.getMob(mobId);
	if (mob === undefined) {
		return;
	}
	const x = mob.entity.c.x;
	const y = mob.entity.c.y;

	var e = new EffectAnimation(x, y, .5);
	
	mobset.spriteLoader.fromConfig(alias).then((alias) => {
		e.sprite = mobset.spriteLoader.add(mobset.scene, alias);
		e.sprite.depth = 100;
		e.sprite.scaleX = .75;
		e.sprite.scaleY = .75;
	})

	e.fade = true;
	return e;
}

function createMeleeZap(mobset, sourceId, targetId, alias) {
	const source = mobset.getMob(sourceId);
	if (source === undefined) {
		return;
	}
	const target = mobset.getMob(targetId);
	if (target === undefined) {
		return;
	}

	const ox = (target.entity.c.x - source.entity.c.x);
	const oy = (target.entity.c.y - source.entity.c.y);

	const x = source.entity.c.x + (ox / 2);
	const y = source.entity.c.y + (oy / 2);
	var e = new EffectAnimation(x, y, .5);
	e.vx = ox
	e.vy = oy
	
	var which = "north";
	if (source.msg.getX() > target.msg.getX()) {
		which = "west";
	}
	if (source.msg.getX() < target.msg.getX()) {
		which = "east";
	}
	if (source.msg.getY() > target.msg.getY()) {
		which = "south";
	}
	if (source.msg.getY() < target.msg.getY()) {
		which = "north";
	}

	mobset.spriteLoader.fromConfig(alias).then((alias) => {
		e.sprite = mobset.spriteLoader.add(mobset.scene, alias);
		e.sprite.depth = 100;
		e.sprite.scaleX = .75;
		e.sprite.scaleY = .75;
		
		const key = `${alias}:${which}`;
		if (!e.sprite.anims?.animationManager.anims.has(key)) {
			return;
		}
		e.sprite.play(key, false);
	})

	e.fade = true;
	return e;
}

function createSpeech(mobset, mobId, text) {
	const mob = mobset.getMob(mobId);
	if (mob === undefined) {
		return;
	}

	var x, y;
	if (mob.entity !== undefined) {
		x = mob.entity.c.x;
		y = mob.entity.c.y;
	} else {
		x = mob.sprite.x;
		y = mob.sprite.y;
	}

	const dx = x;
	const dy = y - TILE_WIDTH;

	var e = new EffectAnimation(dx, dy, 5);
	e.sprite = mobset.scene.add.text(dx, dy, text, {
		fontSize: 20,
		color: 'white',
		stroke: 'black',
		strokeThickness: 2,
	})
	e.sprite.depth = 100;
	e.vy = -5;
	e.fade = true;

	return e;
}

function createChannel(mobset, source_id, target_id, kind, value) {
	const source = mobset.getMob(source_id);
	const target = mobset.getMob(target_id);
	if (source?.entity === undefined) {
		console.log(`cannot channel from ${source_id}`)
		return
	}
	if (target?.entity === undefined) {
		console.log(`cannot channel to ${target_id}`)
		return
	}

	const sx = source.entity.c.x;
	const sy = source.entity.c.y;
	const tx = target.entity.c.x;
	const ty = target.entity.c.y;

	const mx = (sx + tx) / 2;
	const my = (sy + ty) / 2;

	const ox = Math.min(sx, tx)
	const oy = Math.min(sy, ty)

	const arcWidth = 15;
	var arcPoints = [sx - ox, sy - oy];
	var cx = sx;
	var cy = sy;
	for (let i = 0; i < 5; i++) {
		cx = (cx * 3 + tx) / 4 + (Math.random() - 0.5) * arcWidth;
		cy = (cy * 3 + ty) / 4 + (Math.random() - 0.5) * arcWidth;
		arcPoints.push(cx - ox, cy - oy);
	}

	var channelColor = "0xaaaaaa";
	switch (kind) {
	case "blue":
		channelColor = "0x0000ff";
		break;
	case "white":
		channelColor = "0xffffff";
		break;
	}

	const arc = mobset.scene.add.polygon(
		mx, my,
		arcPoints,
		channelColor,
		1,
	)
	var e = new EffectAnimation(mx, my, .3);
	e.sprite = arc;
	e.sprite.depth = 25;
	e.fade = true;
	return e;
}

function createBolt(mobset, source_id, target_id, color, intensity) {
	const source = mobset.getMob(source_id);
	const target = mobset.getMob(target_id);

	const sx = source.entity.c.x;
	const sy = source.entity.c.y;
	const tx = target.entity.c.x;
	const ty = target.entity.c.y;

	const vx = (tx - sx) / .25;
	const vy = (ty - sy) / .25;
	const blob = mobset.scene.add.circle(sx, sy, 10, color, 1);




	var e = new EffectAnimation(sx, sy, .25);
	e.sprite = blob;
	e.sprite.depth = 25;
	e.vx = vx;
	e.vy = vy;
	e.fade = true;
	return e;
}

const MELEE_ZAPS = {
	"slash": "effects/slash",
	"sword": "effects/sword",
	"ax": "effects/ax",
	"dagger": "effects/dagger",
	"spear": "effects/spear",
}

const MAGIC_BOLTS = {
	"waterbolt": "blue",
	"airbolt": "white",
}

export default function CreateEffectAnimations(mobset, effect) {
	console.log(effect.toObject());
	const zapAlias = MELEE_ZAPS[effect.getType()];
	if (zapAlias !== undefined) {
		return [createMeleeZap(mobset, effect.getSource(), effect.getTarget(), zapAlias)];
	}
	const boltColor = MAGIC_BOLTS[effect.getType()];
	if (boltColor !== undefined) {
		return [createBolt(mobset, effect.getSource(), effect.getTarget(), boltColor, effect.getValue())]
	}
	switch (effect.getType()) {
		case "damage":
			return [createDamage(mobset, effect.getTarget(), effect.getValue())];
		case "absorb":
			return [createAbsorb(mobset, effect.getTarget(), effect.getValue())];
		case "fist":
			mobset.scene.soundEffects.play("punching");
			return [createZap(mobset, effect.getTarget(), "effects/fist")];
		case "slash":
			mobset.scene.soundEffects.play("punching");
			return [createZap(mobset, effect.getTarget(), "effects/slash")];
		case "bite":
			mobset.scene.soundEffects.play("eating");
			return [createZap(mobset, effect.getTarget(), "effects/bite")];
		case "cooldown":
			return [createCooldown(mobset, effect.getSource(), effect.getValue())];
		case "speech":
			return [createSpeech(mobset, effect.getSource(), effect.getData())];
		case "channel":
			return [createChannel(mobset, effect.getSource(), effect.getTarget(), effect.getData(), effect.getValue())]
		default:
			return [];

	}
}
