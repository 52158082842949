import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {
	signOut as fbSignOut,
} from 'firebase/auth';

function SignOut({ auth, user, setUser }) {

	function signOut() {
		fbSignOut(auth)
		.then(() => {
			setUser(null);
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			console.log(`sign-out error: ${errorCode} ${errorMessage}`);
		});
	}

	return (
		<header className="flex h-16 items-center border-b px-4 mb-8">
			<a className="flex items-center gap-2" href="#">
				<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-current text-white" viewBox="0 -960 960 960">
					<path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z"/>
				</svg>
				<span className="text-lg font-semibold">{user.email}</span>
			</a>
			<button onClick={signOut} className="inline-flex items-center justify-center text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-9 rounded-md px-3 ml-auto">
				Sign Out
			</button>
		</header>
	);
}

export default SignOut;
