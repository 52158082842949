import React from 'react';
import AceEditor from 'react-ace';

// Import necessary modes and themes for Ace Editor
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';

const PythonEditor = ({ defaultValue, onChange }) => {
	return (
		<AceEditor
			mode="python"
			theme="monokai"
			name="pythonCodeEditor"
			value={defaultValue}
			onChange={onChange}
			width="100%"
			height="300px"
			fontSize={18}
			setOptions={{
				showLineNumbers: true,
				tabSize: 4,
				useSoftTabs: false,
			}}
		/>
	);
};

export default PythonEditor;
