import React from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import PythonEditor from './PythonEditor';
import LogViewer from './LogViewer';

function CodeView({ companionID, thonkText, updateThonkText, thonkTank }) {
	return (
		<Container className="ide">
			<Row>
				<Button onClick={() => {
					thonkTank.loadForMob(companionID, thonkText);
				}}>Attach</Button>
			</Row>
			<Row>
				<Col className="script-editor">
					<PythonEditor id="thonktext" defaultValue={thonkText} onChange={updateThonkText}></PythonEditor>
				</Col>
			</Row>
			<Row>
				<Col className="log-viewer">
					<LogViewer companionID={companionID} thonkTank={thonkTank}/>
				</Col>
			</Row>
		</Container>
	);
}

export default CodeView;
