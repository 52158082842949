import React from 'react';

// this lookup table maps react event.target.value to phaser keycode string aliases.
const specialCodes = {
	'`': 'backtick',
	'ArrowUp': 'up',
	'ArrowDown': 'down',
	'ArrowLeft': 'left',
	'ArrowRight': 'right',
	'1': 'one',
	'2': 'two',
	'3': 'three',
	'4': 'four',
	'5': 'five',
	'6': 'six',
	'7': 'seven',
	'8': 'eight',
	'9': 'nine',
	'0': 'zero',
	' ': 'space',
}

const KeyBindingRow = ({ controlName, controlValue, setControls, controls }) => {

	function mapKey(k) {
		if (k in specialCodes) {
			return specialCodes[k];
		}
		return k;
	}

	const handleChange = (event) => {
		const { name, value } = event.target;
		setControls((prevControls) => ({
			...prevControls,
			[name]: mapKey(value),
		}));
	};

	const handleKeyDown = (event) => {
		const keyName = event.key;
		const { name } = event.target;

		setControls((prevControls) => ({
			...prevControls,
			[name]: mapKey(keyName),
		}));

		event.target.blur();
	};

	return (
		<div className="grid grid-cols-3 gap-2 items-center">
			<label htmlFor={controlName}>{controlName}</label>
			<input
				type="text"
				name={controlName}
				value={controlValue}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				placeholder="Press a key"
				className="col-span-2 m-0"
			/>
		</div>
	);
}

export default KeyBindingRow;
