import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Landing.css';

function Header({auth, user, setUser}) {
	return (
		<div>
			<Container fluid>
				<Row>
					<Col className="header-art">
						Art
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Header;
