import React, {useState, useEffect} from 'react';

function LogViewer({ companionID, thonkTank }) {
	const [logText, setLogText] = useState("");


	useEffect(()=> {
		if (thonkTank === null) {
			return;
		}
		setLogText(thonkTank.getLogText(companionID));
	}, [companionID, thonkTank]);

	useEffect(() => {
		function setLogForID(id, txt) {
			if (id !== companionID) {
				return;
			}
			setLogText(txt);
		}
		if (thonkTank != null) {
			thonkTank.setLogForID = setLogForID;
		}
	}, [companionID, thonkTank, setLogText]);
	
	return (
		<pre><code>{ logText }</code></pre>
	);
};

export default LogViewer;
