
import Mob from './Mob';
import CreateEffectAnimations from './Effects';
import { storedTank } from '../../primus/thonking/thonktank';
import { BASE_SPEED, TILE_WIDTH } from '../../constants.js';
var primuspb = require('../../primus/primuspb/primus_pb.js')

const HEAD_Z = 4;
const ARMS_Z = -1;
const BODY_Z = -3;
const LEGS_Z = -2;

class Entity {
	c;
	anims;
	facing;

	head_alias;
	arms_alias;
	body_alias;
	legs_alias;

	constructor(c) {
		this.c = c;
		this.anims = this;
		this.facing = "south";
		this.head_alias = null;
		this.arms_alias = null;
		this.body_alias = null;
		this.legs_alias = null;
	}

	add(spr) {
		this.c.add(spr);
		this.c.list?.forEach(child => {
			child.setInteractive();
			child.mob = this.c.mob;
		});
	}

	removeEquipmentSprite(alias) {
		this.c.list?.forEach(child => {
			if (child.alias === alias) {
				child.destroy();
			}
		});
	}

	setInteractive() {
		// temporary stub, anything added to the entity becomes interactive.
	}

	setScale(sc) {
		// probably don't need to do this.
	}

	setDepth(d) {
		this.c.depth = d;
	}

	setVelocity(vx, vy) {
		this.c.body.setVelocity(vx, vy);
	}

	setPosition(x, y) {
		this.c.setPosition(x, y);
	}

	play(which, loop) {
		this.c.list?.forEach(child => {
			if (child.play === undefined) {
				return;
			}
			const key = `${child.alias}:${which}`;
			if (!child.anims?.animationManager.anims.has(key)) {
				return;
			}
			child.play(key, loop);
		});
	}

	resume() {
		this.c.list?.forEach(child => {
			child.anims?.resume();
		});
	}

	pause() {
		this.c.list?.forEach(child => {
			child.anims?.pause();
		});
	}

	destroy() {
		this.c.destroy();
	}
}

class MobSet {
	constructor(playerID, scene, mainCamera, spriteLoader) {
		this.playerID = playerID;
		this.scene = scene;
		this.physics = scene.physics;
		this.mainCamera = mainCamera;
		this.spriteLoader = spriteLoader;
		this.mobs = {};
		this.focus = null;
		
		this.targetSprite = null;
		this.effectAnimations = [];
	}

	makeEntity(mob, x, y) {
		const c = this.scene.add.container(x, y);
		c.setSize(100, 100);
		this.scene.physics.world.enableBody(c);
		c.mob = mob;
		return new Entity(c);
	}

	mapX(frameX) {
		return frameX * TILE_WIDTH + (TILE_WIDTH / 2);
	}
	mapY(frameY) {
		return frameY * - TILE_WIDTH + (TILE_WIDTH / 2);
	}

	addMob(mob, x, y) {
		if (mob.id in this.mobs) {
			console.error(`replacing duplicate mob with ID ${mob.id}`)
			this.removeMob(mob.id);
		}
		console.log(`adding mob ${mob.id}`);
		console.dir(mob.msg.toObject());
		mob.move(x, y);

		mob.entity = this.makeEntity(mob, this.mapX(x), this.mapY(y));
		mob.entity.c.depth = 5;
		this.spriteLoader.fromConfig(mob.spriteset).then((alias)=>{
			mob.entity.add(this.spriteLoader.add(this.scene, alias));
		});
		
		this.mobs[mob.id] = mob;
		if (mob.type === primuspb.MobType.MOB_PRIMUS && mob.controllerID === this.playerID) {
			this.setFocus(mob);
		}
		mob.addIDDecor(this.scene);
		mob.addHealthbar(this.scene);

	}

	updateDecorations() {
		for (let mob of Object.values(this.mobs)) {
			mob.updateDecorations()
		}
	}

	setFocus(mob) {
		console.log(`setting mob ${mob.id} as focus for ${this.playerID}`);
		this.focus = mob;
		// disable lerp for now.
		if (this.focus.entity !== null) {
			this.mainCamera.startFollow(this.focus.entity.c, false, 1, 1);
		}
	}

	setTarget(mob) {
		if (this.targetSprite != null) {
			this.targetSprite.destroy();
		}
		if (mob != null) {
			console.log(`setting target ${mob.id}`);
			mob.addTargetSprite(this.scene, this.spriteLoader).then((target) => {
				this.targetSprite = target;
			});
		} else {
			if (this.targetSprite != null) {
				console.log("clearing target");
				this.targetSprite = null;
			}
		}
	}

	getFocus() {
		return this.focus;
	}

	removeMob(id) {
		var mob = this.mobs[id];
		mob.remove()
		delete this.mobs[id];

		console.log(`removed mob ${mob.id}`)
		this.scene.untarget(mob);
	}

	getMob(id) {
		return this.mobs[id];
	}

	thonk(frame, sendAction) {
		if (storedTank.tank == null) {
			return
		}
		const frameObj = frame.toObject();
		storedTank.tank.prepareState(frameObj);
		frame.getMobsList().forEach((mobMsg) => {
			// Only companions get thonked.
			if (mobMsg.getType() !== primuspb.MobType.MOB_COMPANION) {
				return;
			}
			// Only OUR companions get thonked.
			if (mobMsg.getControllerId() !== this.focus.id) {
				return;
			}
			storedTank.tank.thonk(mobMsg.getId()).then((act) => {
				if (act == null) {
					return;
				}
				sendAction(act);
			});
		})
	}

	applyFrame(frame) {
		const ml = this;

		const visibleMobIDs = new Set();

		frame.getEffectsList().forEach((effect) => {
			const newEffects = CreateEffectAnimations(this, effect);

			newEffects.forEach((e) => {
				this.effectAnimations.push(e);
			});
		});

		frame.getMobsList().forEach((mobMsg) => {
			let mob = ml.getMob(mobMsg.getId());
			if (mob == null) {
				// Add a new mob to the scene.
				mob = new Mob(mobMsg);
				ml.addMob(mob, mobMsg.getX(), mobMsg.getY());
				visibleMobIDs.add(mob.id);
				return;
			}
			mob.move(mobMsg.getPx(), mobMsg.getPy());
			mob.msg = mobMsg;
			mob.entity.setPosition(ml.mapX(mobMsg.getPx()), ml.mapY(mobMsg.getPy()));
			visibleMobIDs.add(mob.id);
		});

		// Drop mobs missing from the frame.
		for (let mobID of Object.keys(this.mobs)) {
			if (!visibleMobIDs.has(mobID)) {
				console.log(`mob ${mobID} not visible (${Array.from(visibleMobIDs)}) in frame`)
				this.removeMob(mobID);
			}
		}

		for (const mid in this.mobs) {
			const mob = this.mobs[mid];
			mob.setSkull(this.scene, this.spriteLoader, mob.msg.getCondition().getDead());

			if (mob.msg.getCondition().getNonblocking()) {
				mob.entity.setDepth(2);
			} else {
				mob.entity.setDepth(4);
			}

			const setEquipSprite = (alias, current, z) => {
				if (alias === undefined || alias == "") {
					alias = null;
				}
				if (alias === current) {
					return alias;
				}
				mob.entity.removeEquipmentSprite(current);
				if (alias === null) {
					return null;
				}
				this.spriteLoader.fromConfig(alias).then((alias)=>{
					const spr = this.spriteLoader.add(this.scene, alias);
					spr.z = z;
					mob.entity.add(spr);
				});
				return alias;
			}
			mob.entity.head_alias = setEquipSprite(mob.msg.getEquipHead()?.getSpriteAsset(), mob.entity.head_alias, HEAD_Z);
			mob.entity.arms_alias = setEquipSprite(mob.msg.getEquipArms()?.getSpriteAsset(), mob.entity.arms_alias, ARMS_Z);
			mob.entity.body_alias = setEquipSprite(mob.msg.getEquipBody()?.getSpriteAsset(), mob.entity.body_alias, BODY_Z);
			mob.entity.legs_alias = setEquipSprite(mob.msg.getEquipLegs()?.getSpriteAsset(), mob.entity.legs_alias, LEGS_Z);
		}

		const movingMobIDs = new Set();
		frame.getActionsList().forEach((action) => {
			const mob = ml.getMob(action.getMobId());
			if (mob == null) {
				return;
			}

			if (action.getMovement() != primuspb.Direction.DIR_NONE) {
				movingMobIDs.add(mob.id);
			}

			switch (action.getMovement()) {
				case primuspb.Direction.DIR_NORTH:
					mob.entity.play("north", true);
					mob.entity.setVelocity(0, -BASE_SPEED);
					mob.entity.resume();
					mob.entity.facing = "north";
					break;
				case primuspb.Direction.DIR_EAST:
					mob.entity.play("east", true);
					mob.entity.setVelocity(BASE_SPEED, 0);
					mob.entity.resume();
					mob.entity.facing = "east";
					break;
				case primuspb.Direction.DIR_SOUTH:
					mob.entity.play("south", true);
					mob.entity.setVelocity(0, BASE_SPEED);
					mob.entity.resume();
					mob.entity.facing = "south";
					break;
				case primuspb.Direction.DIR_WEST:
					mob.entity.play("west", true);
					mob.entity.setVelocity(-BASE_SPEED, 0);
					mob.entity.resume();
					mob.entity.facing = "west";
					break;
			}
		});
		
		for (const mid in this.mobs) {
			if (movingMobIDs.has(mid)) {
				continue;
			}
			const mob = ml.mobs[mid];
			mob.entity.setVelocity(0, 0);
			if (mob?.entity?.facing !== undefined) {
				mob.entity.play(`stand-${mob.entity.facing}`, false);
			}
		}
	}

	updateEffectAnimations(time) {
		var nextEffectAnimations = [];
		this.effectAnimations.forEach((ea) => {
			if (ea === undefined) {
				return;
			}
			if (ea.expired(time)) {
				ea.destroy();
				return;
			}
			ea.update(time);
			nextEffectAnimations.push(ea);
		});
		this.effectAnimations = nextEffectAnimations;
	}
}

export default MobSet;
