import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Landing.css';

function Footer() {
	return (
		<div>
			Footer
		</div>
	);
}

export default Footer;
