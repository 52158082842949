import React, { useState, useEffect } from 'react';
import CodeView from './components/CodeView';
import MobView from './components/MobView';
import MobSkillsView from './components/MobSkillsView';
import TabButton from './components/TabButton';
import './styles/GameTabView.css';

function GameTabView({ tickCount, targetMob, thonkTank, game }) {
	const [activeTab, setActiveTab] = useState('basicMob');

	const [thonkText, setThonkText] = useState(
		"import thonking\nfrom thonking.path import Pather\ndef thonk(id, state, recall):\n\tme = state.mobs[id]\n\tboss = state.mobs[me.controller_id]\n\ta = thonking.Action()\n\tif abs(me.x-boss.x)+abs(me.y-boss.y) > 3:\n\t\ta.movement = Pather(state.tilemap, boss.x, boss.y).search(me.x, me.y,50)\n\treturn a\n"
	);

	const updateThonkText = (newText) => {
		setThonkText(newText);
	}

	const [companionID, setCompanionID] = useState("click a companion");
	useEffect(() => {
		if (targetMob != null) {
			setCompanionID(targetMob.id);
		}
	}, [targetMob]);

	const mob = targetMob?.msg?.toObject();

	return (
		<div className="game-tab-view">
			<pre>{targetMob?.id || "target"}</pre>
			<div className="tabs">
				<TabButton
					displayText="Inspect"
					onClick={() => setActiveTab('basicMob')}
					isActive={activeTab === 'basicMob'}
				/>
				<TabButton
					displayText="Code"
					onClick={() => setActiveTab('code')}
					isActive={activeTab === 'code'}
				/>
				<TabButton
					displayText="Stats"
					onClick={() => setActiveTab('mobSkills')}
					isActive={activeTab === 'mobSkills'}
				/>
				{/* ... other tabs */}
			</div>

			<div className="content">
				{activeTab === 'basicMob' && (
					<MobView tickCount={tickCount} mob={mob} game={game} />
				)}
				{activeTab === 'mobSkills' && (
					<MobSkillsView tickCount={tickCount} mob={mob}/>
				)}
				{activeTab === 'code' && (
					<CodeView
						companionID={companionID}
						thonkText={thonkText}
						updateThonkText={updateThonkText}
						thonkTank={thonkTank}
					/>
				)}
				{/* ... other views */}
			</div>
		</div>
	);
}

export default GameTabView;
