import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation } from 'react-router-dom';

import Game from './Game';
import Landing from './interface/landing/Landing';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
	getAuth,
} from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyC-w2t2LleF4J96P5TPcJsTlX03QWcP54Y",
	authDomain: "primus-game.firebaseapp.com",
	projectId: "primus-game",
	storageBucket: "primus-game.appspot.com",
	messagingSenderId: "555033565356",
	appId: "1:555033565356:web:3cb97b6ecd954526aa2709"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

const Main = () => {
	const [persistentUser, loading, error] = useAuthState(auth);
	const [user, setUser] = useState(undefined);
	const [playerID, setPlayerID] = useState("");
	const [gameHooks, setGameHooks] = useState({});
	const [clearGame, setClearGame] = useState(()=>{
		console.log("game not yet started");
	})

	const location = useLocation();
	useEffect(()=> {
		if (location.pathname === "/" && clearGame !== undefined) {
			clearGame();
		}
	}, [location, clearGame]);

	useEffect(()=> {
		if (error !== undefined) {
			console.dir(error);
			return;
		}
		if (!loading) {
			setUser(persistentUser);
		}
	}, [persistentUser, loading, error]);

	return (
		<Routes>
			<Route path='/' element={
				<Landing
					auth={auth}
					user={user}
					setUser={setUser}
					playerID={playerID}
					setPlayerID={setPlayerID}
					clearGame={clearGame} />
			}></Route>
			<Route path='/play' element={
				<Game
					user={user}
					playerID={playerID}
					setClearGame={setClearGame}
					gameHooks={gameHooks}
					setGameHooks={setGameHooks} />
			}></Route>
		</Routes>
	);
}	

export default Main;
