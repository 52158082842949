var primuspb = require('../../primus/primuspb/primus_pb.js')

const MAX_LOG_LENGTH = 1000;

export var storedTank = {};

export class ThonkTank {
	pyodide;
	thonkers;
	setLogForID;

	logForId;

	constructor(pyodide) {
		this.pyodide = pyodide;
		this.thonkers = new Set();
		this.logForId = {};
		this.setLogText = null;
		this.pyodide.runPython("import thonking");
		this.pyodide.runPython("_thonktank = {}");
		this.pyodide.runPython("_recalltank = {}");
	}
	loadForMob(id, thonkScript) {
		console.log(`loading thonk for ${id}:\n${thonkScript}`);
		this.thonkers.add(id);
		try {
			this.pyodide.runPython(thonkScript);
			this.pyodide.runPython(`_thonktank['${id}'] = thonk`);
			this.clearLogText(id);
		} catch (ex) {
			console.log(`while loading thonk: ${ex}`);
			this.addLogText(id, ex);
		}
	}
	unloadForMob(id) {
		console.log(`unloading thonk for ${id}`)
		this.thonkers.delete(id);
	}

	addLogText(id, txt) {
		const prevTxt = this.logForId[id] || "";
		const nextTxt = `${prevTxt}${txt}`;
		this.logForId[id] = nextTxt.substring(nextTxt.length-MAX_LOG_LENGTH);
		if (this.setLogForID !== null) {
			this.setLogForID(id, this.logForId[id]);
		}
	}

	clearLogText(id, txt) {
		this.logForId[id] = "";
		if (this.setLogForID !== null) {
			this.setLogForID(id, this.logForId[id]);
		}
	}

	getLogText(id) {
		return this.logForId[id] || "";
	}

	async loadTilemap(tilemap) {
		try {
			const py = this.pyodide.runPython;
			const tilemapJSON = JSON.stringify(tilemap.toObject());
			this.pyodide.globals.set('tilemapjson', tilemapJSON);
			py("_cached_tilemap = thonking.TilemapFromJSON(tilemapjson)");
		} catch (ex) {
			console.log(`while loading tilemap: ${ex}`);
			return null;
		}
	}

	prepareState(frame) {
		try {
			const py = this.pyodide.runPython;
			const stateJSON = JSON.stringify(frame);
			this.pyodide.globals.set('statejson', stateJSON);
			py("state = thonking.StateFromJSON(statejson)");
			// get the tilemap created by loadTilemap()
			// It takes too long to reload this unchanged data each time.
			py("state.tilemap = _cached_tilemap");
			py("_cached_tilemap._placeMobs(state.mobs)");
		} catch (ex) {
			console.log(`while placing mobs: ${ex}`);
			return null;
		}
	}

	async thonk(id, frame) {
		if (!this.thonkers.has(id)) {
			return null;
		}

		this.pyodide.setStdout({raw: (byt) => {
			this.addLogText(id, String.fromCharCode(byt));
		}});

		try {
			const py = this.pyodide.runPython;
			py("action = None");
			py(`_recall = _recalltank.get('${id}', thonking.Recall())`);

			try {
				py(`action = _thonktank['${id}']('${id}', state, _recall)`);
			} catch (ex) {
				console.log(`while thonking for ${id}: ${ex}`);
				this.addLogText(id, `\n${ex}`);
				this.unloadForMob(id)
			}
			py(`_recalltank['${id}'] = _recall`);
			py(`actionjson = action.dump() if action else {}`);
			const actionJSON = this.pyodide.globals.get('actionjson').toJs();
			var a = new primuspb.Action();
			a.setMobId(id);
			a.setMovement(actionJSON.get('movement'));
			a.setUse(actionJSON.get('use'));
			a.setTargetId(actionJSON.get('target_id'));
			a.setCommand(actionJSON.get('command'))
			a.setEquipId(actionJSON.get('equip_id'))
			a.setDiscardId(actionJSON.get('discard_id'))
			a.setRemove(actionJSON.get('remove'))
			return a;
		} catch (ex) {
			console.log(`while processing thonk for ${id}: ${ex}`);
			this.addLogText(id, `\n${ex}`);
			this.unloadForMob(id)
			return null;
		}
	}
}
