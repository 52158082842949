// source: primus.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.primus.Accuracy', null, global);
goog.exportSymbol('proto.primus.Action', null, global);
goog.exportSymbol('proto.primus.Attribute', null, global);
goog.exportSymbol('proto.primus.Attributes', null, global);
goog.exportSymbol('proto.primus.Beacon', null, global);
goog.exportSymbol('proto.primus.Condition', null, global);
goog.exportSymbol('proto.primus.Direction', null, global);
goog.exportSymbol('proto.primus.Effect', null, global);
goog.exportSymbol('proto.primus.Frame', null, global);
goog.exportSymbol('proto.primus.Impact', null, global);
goog.exportSymbol('proto.primus.Init', null, global);
goog.exportSymbol('proto.primus.Item', null, global);
goog.exportSymbol('proto.primus.Mana', null, global);
goog.exportSymbol('proto.primus.MobType', null, global);
goog.exportSymbol('proto.primus.Mobile', null, global);
goog.exportSymbol('proto.primus.Property', null, global);
goog.exportSymbol('proto.primus.Slot', null, global);
goog.exportSymbol('proto.primus.Tilemap', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Init = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.primus.Init, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Init.displayName = 'proto.primus.Init';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Frame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.primus.Frame.repeatedFields_, null);
};
goog.inherits(proto.primus.Frame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Frame.displayName = 'proto.primus.Frame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Tilemap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.primus.Tilemap.repeatedFields_, null);
};
goog.inherits(proto.primus.Tilemap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Tilemap.displayName = 'proto.primus.Tilemap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Beacon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.primus.Beacon.repeatedFields_, null);
};
goog.inherits(proto.primus.Beacon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Beacon.displayName = 'proto.primus.Beacon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Property = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.primus.Property, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Property.displayName = 'proto.primus.Property';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Mobile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.primus.Mobile.repeatedFields_, null);
};
goog.inherits(proto.primus.Mobile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Mobile.displayName = 'proto.primus.Mobile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Mana = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.primus.Mana, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Mana.displayName = 'proto.primus.Mana';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.primus.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Condition.displayName = 'proto.primus.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.primus.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Action.displayName = 'proto.primus.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Attributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.primus.Attributes.repeatedFields_, null);
};
goog.inherits(proto.primus.Attributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Attributes.displayName = 'proto.primus.Attributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Attribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.primus.Attribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Attribute.displayName = 'proto.primus.Attribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Accuracy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.primus.Accuracy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Accuracy.displayName = 'proto.primus.Accuracy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.primus.Item.repeatedFields_, null);
};
goog.inherits(proto.primus.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Item.displayName = 'proto.primus.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Impact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.primus.Impact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Impact.displayName = 'proto.primus.Impact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.primus.Effect = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.primus.Effect, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.primus.Effect.displayName = 'proto.primus.Effect';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Init.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Init.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Init} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Init.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    account: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Init}
 */
proto.primus.Init.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Init;
  return proto.primus.Init.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Init} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Init}
 */
proto.primus.Init.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Init.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Init.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Init} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Init.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.primus.Init.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Init} returns this
 */
proto.primus.Init.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.primus.Init.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Init} returns this
 */
proto.primus.Init.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account = 3;
 * @return {string}
 */
proto.primus.Init.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Init} returns this
 */
proto.primus.Init.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.primus.Frame.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Frame.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Frame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Frame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Frame.toObject = function(includeInstance, msg) {
  var f, obj = {
    number: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mobs: jspb.Message.toObjectList(msg.getMobsList(),
    proto.primus.Mobile.toObject, includeInstance),
    actions: jspb.Message.toObjectList(msg.getActionsList(),
    proto.primus.Action.toObject, includeInstance),
    effects: jspb.Message.toObjectList(msg.getEffectsList(),
    proto.primus.Effect.toObject, includeInstance),
    tilemap: (f = msg.getTilemap()) && proto.primus.Tilemap.toObject(includeInstance, f),
    reload_area: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Frame}
 */
proto.primus.Frame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Frame;
  return proto.primus.Frame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Frame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Frame}
 */
proto.primus.Frame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumber(value);
      break;
    case 2:
      var value = new proto.primus.Mobile;
      reader.readMessage(value,proto.primus.Mobile.deserializeBinaryFromReader);
      msg.addMobs(value);
      break;
    case 3:
      var value = new proto.primus.Action;
      reader.readMessage(value,proto.primus.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 4:
      var value = new proto.primus.Effect;
      reader.readMessage(value,proto.primus.Effect.deserializeBinaryFromReader);
      msg.addEffects(value);
      break;
    case 5:
      var value = new proto.primus.Tilemap;
      reader.readMessage(value,proto.primus.Tilemap.deserializeBinaryFromReader);
      msg.setTilemap(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReloadArea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Frame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Frame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Frame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Frame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.primus.Mobile.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.primus.Action.serializeBinaryToWriter
    );
  }
  f = message.getEffectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.primus.Effect.serializeBinaryToWriter
    );
  }
  f = message.getTilemap();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.primus.Tilemap.serializeBinaryToWriter
    );
  }
  f = message.getReloadArea();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 number = 1;
 * @return {number}
 */
proto.primus.Frame.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Frame} returns this
 */
proto.primus.Frame.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Mobile mobs = 2;
 * @return {!Array<!proto.primus.Mobile>}
 */
proto.primus.Frame.prototype.getMobsList = function() {
  return /** @type{!Array<!proto.primus.Mobile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Mobile, 2));
};


/**
 * @param {!Array<!proto.primus.Mobile>} value
 * @return {!proto.primus.Frame} returns this
*/
proto.primus.Frame.prototype.setMobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.primus.Mobile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Mobile}
 */
proto.primus.Frame.prototype.addMobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.primus.Mobile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Frame} returns this
 */
proto.primus.Frame.prototype.clearMobsList = function() {
  return this.setMobsList([]);
};


/**
 * repeated Action actions = 3;
 * @return {!Array<!proto.primus.Action>}
 */
proto.primus.Frame.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.primus.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Action, 3));
};


/**
 * @param {!Array<!proto.primus.Action>} value
 * @return {!proto.primus.Frame} returns this
*/
proto.primus.Frame.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.primus.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Action}
 */
proto.primus.Frame.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.primus.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Frame} returns this
 */
proto.primus.Frame.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * repeated Effect effects = 4;
 * @return {!Array<!proto.primus.Effect>}
 */
proto.primus.Frame.prototype.getEffectsList = function() {
  return /** @type{!Array<!proto.primus.Effect>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Effect, 4));
};


/**
 * @param {!Array<!proto.primus.Effect>} value
 * @return {!proto.primus.Frame} returns this
*/
proto.primus.Frame.prototype.setEffectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.primus.Effect=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Effect}
 */
proto.primus.Frame.prototype.addEffects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.primus.Effect, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Frame} returns this
 */
proto.primus.Frame.prototype.clearEffectsList = function() {
  return this.setEffectsList([]);
};


/**
 * optional Tilemap tilemap = 5;
 * @return {?proto.primus.Tilemap}
 */
proto.primus.Frame.prototype.getTilemap = function() {
  return /** @type{?proto.primus.Tilemap} */ (
    jspb.Message.getWrapperField(this, proto.primus.Tilemap, 5));
};


/**
 * @param {?proto.primus.Tilemap|undefined} value
 * @return {!proto.primus.Frame} returns this
*/
proto.primus.Frame.prototype.setTilemap = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Frame} returns this
 */
proto.primus.Frame.prototype.clearTilemap = function() {
  return this.setTilemap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Frame.prototype.hasTilemap = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string reload_area = 6;
 * @return {string}
 */
proto.primus.Frame.prototype.getReloadArea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Frame} returns this
 */
proto.primus.Frame.prototype.setReloadArea = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.primus.Tilemap.repeatedFields_ = [7,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Tilemap.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Tilemap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Tilemap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Tilemap.toObject = function(includeInstance, msg) {
  var f, obj = {
    alias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    west_x: jspb.Message.getFieldWithDefault(msg, 5, 0),
    north_y: jspb.Message.getFieldWithDefault(msg, 6, 0),
    span_x: jspb.Message.getFieldWithDefault(msg, 8, 0),
    span_y: jspb.Message.getFieldWithDefault(msg, 9, 0),
    blocks: (f = jspb.Message.getRepeatedBooleanField(msg, 7)) == null ? undefined : f,
    tiled_config: jspb.Message.getFieldWithDefault(msg, 10, ""),
    beacons: jspb.Message.toObjectList(msg.getBeaconsList(),
    proto.primus.Beacon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Tilemap}
 */
proto.primus.Tilemap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Tilemap;
  return proto.primus.Tilemap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Tilemap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Tilemap}
 */
proto.primus.Tilemap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWestX(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNorthY(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpanX(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpanY(value);
      break;
    case 7:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBlocks(values[i]);
      }
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiledConfig(value);
      break;
    case 11:
      var value = new proto.primus.Beacon;
      reader.readMessage(value,proto.primus.Beacon.deserializeBinaryFromReader);
      msg.addBeacons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Tilemap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Tilemap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Tilemap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Tilemap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWestX();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getNorthY();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSpanX();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getSpanY();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBlocksList();
  if (f.length > 0) {
    writer.writePackedBool(
      7,
      f
    );
  }
  f = message.getTiledConfig();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBeaconsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.primus.Beacon.serializeBinaryToWriter
    );
  }
};


/**
 * optional string alias = 1;
 * @return {string}
 */
proto.primus.Tilemap.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 west_x = 5;
 * @return {number}
 */
proto.primus.Tilemap.prototype.getWestX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.setWestX = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 north_y = 6;
 * @return {number}
 */
proto.primus.Tilemap.prototype.getNorthY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.setNorthY = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 span_x = 8;
 * @return {number}
 */
proto.primus.Tilemap.prototype.getSpanX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.setSpanX = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 span_y = 9;
 * @return {number}
 */
proto.primus.Tilemap.prototype.getSpanY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.setSpanY = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated bool blocks = 7;
 * @return {!Array<boolean>}
 */
proto.primus.Tilemap.prototype.getBlocksList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 7));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.setBlocksList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.addBlocks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.clearBlocksList = function() {
  return this.setBlocksList([]);
};


/**
 * optional string tiled_config = 10;
 * @return {string}
 */
proto.primus.Tilemap.prototype.getTiledConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.setTiledConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated Beacon beacons = 11;
 * @return {!Array<!proto.primus.Beacon>}
 */
proto.primus.Tilemap.prototype.getBeaconsList = function() {
  return /** @type{!Array<!proto.primus.Beacon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Beacon, 11));
};


/**
 * @param {!Array<!proto.primus.Beacon>} value
 * @return {!proto.primus.Tilemap} returns this
*/
proto.primus.Tilemap.prototype.setBeaconsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.primus.Beacon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Beacon}
 */
proto.primus.Tilemap.prototype.addBeacons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.primus.Beacon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Tilemap} returns this
 */
proto.primus.Tilemap.prototype.clearBeaconsList = function() {
  return this.setBeaconsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.primus.Beacon.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Beacon.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Beacon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Beacon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Beacon.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 1, ""),
    x: jspb.Message.getFieldWithDefault(msg, 2, 0),
    y: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dx: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dy: jspb.Message.getFieldWithDefault(msg, 5, 0),
    properties: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.primus.Property.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Beacon}
 */
proto.primus.Beacon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Beacon;
  return proto.primus.Beacon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Beacon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Beacon}
 */
proto.primus.Beacon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDx(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDy(value);
      break;
    case 6:
      var value = new proto.primus.Property;
      reader.readMessage(value,proto.primus.Property.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Beacon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Beacon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Beacon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Beacon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDx();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDy();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.primus.Property.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.primus.Beacon.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Beacon} returns this
 */
proto.primus.Beacon.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string kind = 1;
 * @return {string}
 */
proto.primus.Beacon.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Beacon} returns this
 */
proto.primus.Beacon.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 x = 2;
 * @return {number}
 */
proto.primus.Beacon.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Beacon} returns this
 */
proto.primus.Beacon.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 y = 3;
 * @return {number}
 */
proto.primus.Beacon.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Beacon} returns this
 */
proto.primus.Beacon.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 dx = 4;
 * @return {number}
 */
proto.primus.Beacon.prototype.getDx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Beacon} returns this
 */
proto.primus.Beacon.prototype.setDx = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 dy = 5;
 * @return {number}
 */
proto.primus.Beacon.prototype.getDy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Beacon} returns this
 */
proto.primus.Beacon.prototype.setDy = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Property properties = 6;
 * @return {!Array<!proto.primus.Property>}
 */
proto.primus.Beacon.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.primus.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Property, 6));
};


/**
 * @param {!Array<!proto.primus.Property>} value
 * @return {!proto.primus.Beacon} returns this
*/
proto.primus.Beacon.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.primus.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Property}
 */
proto.primus.Beacon.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.primus.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Beacon} returns this
 */
proto.primus.Beacon.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Property.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Property.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Property} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Property.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Property}
 */
proto.primus.Property.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Property;
  return proto.primus.Property.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Property} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Property}
 */
proto.primus.Property.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Property.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Property.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Property} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Property.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.primus.Property.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Property} returns this
 */
proto.primus.Property.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.primus.Property.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Property} returns this
 */
proto.primus.Property.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.primus.Mobile.repeatedFields_ = [12,18,21,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Mobile.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Mobile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Mobile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Mobile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    x: jspb.Message.getFieldWithDefault(msg, 3, 0),
    y: jspb.Message.getFieldWithDefault(msg, 4, 0),
    px: jspb.Message.getFieldWithDefault(msg, 5, 0),
    py: jspb.Message.getFieldWithDefault(msg, 6, 0),
    controller_id: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sprite_asset: jspb.Message.getFieldWithDefault(msg, 8, ""),
    type: jspb.Message.getFieldWithDefault(msg, 9, 0),
    attrs: (f = msg.getAttrs()) && proto.primus.Attributes.toObject(includeInstance, f),
    cooldown: jspb.Message.getFieldWithDefault(msg, 11, 0),
    inventory: jspb.Message.toObjectList(msg.getInventoryList(),
    proto.primus.Item.toObject, includeInstance),
    equip_head: (f = msg.getEquipHead()) && proto.primus.Item.toObject(includeInstance, f),
    equip_body: (f = msg.getEquipBody()) && proto.primus.Item.toObject(includeInstance, f),
    equip_arms: (f = msg.getEquipArms()) && proto.primus.Item.toObject(includeInstance, f),
    equip_legs: (f = msg.getEquipLegs()) && proto.primus.Item.toObject(includeInstance, f),
    condition: (f = msg.getCondition()) && proto.primus.Condition.toObject(includeInstance, f),
    channeled_mana: jspb.Message.toObjectList(msg.getChanneledManaList(),
    proto.primus.Mana.toObject, includeInstance),
    properties: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.primus.Property.toObject, includeInstance),
    metadata: jspb.Message.toObjectList(msg.getMetadataList(),
    proto.primus.Property.toObject, includeInstance),
    beacon: jspb.Message.getFieldWithDefault(msg, 19, ""),
    area: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Mobile}
 */
proto.primus.Mobile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Mobile;
  return proto.primus.Mobile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Mobile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Mobile}
 */
proto.primus.Mobile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setY(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPx(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpriteAsset(value);
      break;
    case 9:
      var value = /** @type {!proto.primus.MobType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 10:
      var value = new proto.primus.Attributes;
      reader.readMessage(value,proto.primus.Attributes.deserializeBinaryFromReader);
      msg.setAttrs(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCooldown(value);
      break;
    case 12:
      var value = new proto.primus.Item;
      reader.readMessage(value,proto.primus.Item.deserializeBinaryFromReader);
      msg.addInventory(value);
      break;
    case 13:
      var value = new proto.primus.Item;
      reader.readMessage(value,proto.primus.Item.deserializeBinaryFromReader);
      msg.setEquipHead(value);
      break;
    case 14:
      var value = new proto.primus.Item;
      reader.readMessage(value,proto.primus.Item.deserializeBinaryFromReader);
      msg.setEquipBody(value);
      break;
    case 15:
      var value = new proto.primus.Item;
      reader.readMessage(value,proto.primus.Item.deserializeBinaryFromReader);
      msg.setEquipArms(value);
      break;
    case 16:
      var value = new proto.primus.Item;
      reader.readMessage(value,proto.primus.Item.deserializeBinaryFromReader);
      msg.setEquipLegs(value);
      break;
    case 17:
      var value = new proto.primus.Condition;
      reader.readMessage(value,proto.primus.Condition.deserializeBinaryFromReader);
      msg.setCondition(value);
      break;
    case 18:
      var value = new proto.primus.Mana;
      reader.readMessage(value,proto.primus.Mana.deserializeBinaryFromReader);
      msg.addChanneledMana(value);
      break;
    case 21:
      var value = new proto.primus.Property;
      reader.readMessage(value,proto.primus.Property.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    case 22:
      var value = new proto.primus.Property;
      reader.readMessage(value,proto.primus.Property.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeacon(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setArea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Mobile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Mobile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Mobile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Mobile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPx();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPy();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getControllerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSpriteAsset();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAttrs();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.primus.Attributes.serializeBinaryToWriter
    );
  }
  f = message.getCooldown();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getInventoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.primus.Item.serializeBinaryToWriter
    );
  }
  f = message.getEquipHead();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.primus.Item.serializeBinaryToWriter
    );
  }
  f = message.getEquipBody();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.primus.Item.serializeBinaryToWriter
    );
  }
  f = message.getEquipArms();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.primus.Item.serializeBinaryToWriter
    );
  }
  f = message.getEquipLegs();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.primus.Item.serializeBinaryToWriter
    );
  }
  f = message.getCondition();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.primus.Condition.serializeBinaryToWriter
    );
  }
  f = message.getChanneledManaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.primus.Mana.serializeBinaryToWriter
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.primus.Property.serializeBinaryToWriter
    );
  }
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.primus.Property.serializeBinaryToWriter
    );
  }
  f = message.getBeacon();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getArea();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.primus.Mobile.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.primus.Mobile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 x = 3;
 * @return {number}
 */
proto.primus.Mobile.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 y = 4;
 * @return {number}
 */
proto.primus.Mobile.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 px = 5;
 * @return {number}
 */
proto.primus.Mobile.prototype.getPx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setPx = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 py = 6;
 * @return {number}
 */
proto.primus.Mobile.prototype.getPy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setPy = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string controller_id = 7;
 * @return {string}
 */
proto.primus.Mobile.prototype.getControllerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sprite_asset = 8;
 * @return {string}
 */
proto.primus.Mobile.prototype.getSpriteAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setSpriteAsset = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional MobType type = 9;
 * @return {!proto.primus.MobType}
 */
proto.primus.Mobile.prototype.getType = function() {
  return /** @type {!proto.primus.MobType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.primus.MobType} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional Attributes attrs = 10;
 * @return {?proto.primus.Attributes}
 */
proto.primus.Mobile.prototype.getAttrs = function() {
  return /** @type{?proto.primus.Attributes} */ (
    jspb.Message.getWrapperField(this, proto.primus.Attributes, 10));
};


/**
 * @param {?proto.primus.Attributes|undefined} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setAttrs = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearAttrs = function() {
  return this.setAttrs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Mobile.prototype.hasAttrs = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 cooldown = 11;
 * @return {number}
 */
proto.primus.Mobile.prototype.getCooldown = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setCooldown = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated Item inventory = 12;
 * @return {!Array<!proto.primus.Item>}
 */
proto.primus.Mobile.prototype.getInventoryList = function() {
  return /** @type{!Array<!proto.primus.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Item, 12));
};


/**
 * @param {!Array<!proto.primus.Item>} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setInventoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.primus.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Item}
 */
proto.primus.Mobile.prototype.addInventory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.primus.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearInventoryList = function() {
  return this.setInventoryList([]);
};


/**
 * optional Item equip_head = 13;
 * @return {?proto.primus.Item}
 */
proto.primus.Mobile.prototype.getEquipHead = function() {
  return /** @type{?proto.primus.Item} */ (
    jspb.Message.getWrapperField(this, proto.primus.Item, 13));
};


/**
 * @param {?proto.primus.Item|undefined} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setEquipHead = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearEquipHead = function() {
  return this.setEquipHead(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Mobile.prototype.hasEquipHead = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Item equip_body = 14;
 * @return {?proto.primus.Item}
 */
proto.primus.Mobile.prototype.getEquipBody = function() {
  return /** @type{?proto.primus.Item} */ (
    jspb.Message.getWrapperField(this, proto.primus.Item, 14));
};


/**
 * @param {?proto.primus.Item|undefined} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setEquipBody = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearEquipBody = function() {
  return this.setEquipBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Mobile.prototype.hasEquipBody = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Item equip_arms = 15;
 * @return {?proto.primus.Item}
 */
proto.primus.Mobile.prototype.getEquipArms = function() {
  return /** @type{?proto.primus.Item} */ (
    jspb.Message.getWrapperField(this, proto.primus.Item, 15));
};


/**
 * @param {?proto.primus.Item|undefined} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setEquipArms = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearEquipArms = function() {
  return this.setEquipArms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Mobile.prototype.hasEquipArms = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Item equip_legs = 16;
 * @return {?proto.primus.Item}
 */
proto.primus.Mobile.prototype.getEquipLegs = function() {
  return /** @type{?proto.primus.Item} */ (
    jspb.Message.getWrapperField(this, proto.primus.Item, 16));
};


/**
 * @param {?proto.primus.Item|undefined} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setEquipLegs = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearEquipLegs = function() {
  return this.setEquipLegs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Mobile.prototype.hasEquipLegs = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Condition condition = 17;
 * @return {?proto.primus.Condition}
 */
proto.primus.Mobile.prototype.getCondition = function() {
  return /** @type{?proto.primus.Condition} */ (
    jspb.Message.getWrapperField(this, proto.primus.Condition, 17));
};


/**
 * @param {?proto.primus.Condition|undefined} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setCondition = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearCondition = function() {
  return this.setCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Mobile.prototype.hasCondition = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated Mana channeled_mana = 18;
 * @return {!Array<!proto.primus.Mana>}
 */
proto.primus.Mobile.prototype.getChanneledManaList = function() {
  return /** @type{!Array<!proto.primus.Mana>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Mana, 18));
};


/**
 * @param {!Array<!proto.primus.Mana>} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setChanneledManaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.primus.Mana=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Mana}
 */
proto.primus.Mobile.prototype.addChanneledMana = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.primus.Mana, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearChanneledManaList = function() {
  return this.setChanneledManaList([]);
};


/**
 * repeated Property properties = 21;
 * @return {!Array<!proto.primus.Property>}
 */
proto.primus.Mobile.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.primus.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Property, 21));
};


/**
 * @param {!Array<!proto.primus.Property>} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.primus.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Property}
 */
proto.primus.Mobile.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.primus.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * repeated Property metadata = 22;
 * @return {!Array<!proto.primus.Property>}
 */
proto.primus.Mobile.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.primus.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Property, 22));
};


/**
 * @param {!Array<!proto.primus.Property>} value
 * @return {!proto.primus.Mobile} returns this
*/
proto.primus.Mobile.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.primus.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Property}
 */
proto.primus.Mobile.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.primus.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};


/**
 * optional string beacon = 19;
 * @return {string}
 */
proto.primus.Mobile.prototype.getBeacon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setBeacon = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string area = 20;
 * @return {string}
 */
proto.primus.Mobile.prototype.getArea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Mobile} returns this
 */
proto.primus.Mobile.prototype.setArea = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Mana.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Mana.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Mana} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Mana.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Mana}
 */
proto.primus.Mana.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Mana;
  return proto.primus.Mana.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Mana} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Mana}
 */
proto.primus.Mana.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Mana.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Mana.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Mana} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Mana.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.primus.Mana.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Mana} returns this
 */
proto.primus.Mana.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.primus.Mana.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Mana} returns this
 */
proto.primus.Mana.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    dead: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    nonblocking: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    shell: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Condition}
 */
proto.primus.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Condition;
  return proto.primus.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Condition}
 */
proto.primus.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDead(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNonblocking(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShell(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDead();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getNonblocking();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getShell();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool dead = 1;
 * @return {boolean}
 */
proto.primus.Condition.prototype.getDead = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.primus.Condition} returns this
 */
proto.primus.Condition.prototype.setDead = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool nonblocking = 2;
 * @return {boolean}
 */
proto.primus.Condition.prototype.getNonblocking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.primus.Condition} returns this
 */
proto.primus.Condition.prototype.setNonblocking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool shell = 3;
 * @return {boolean}
 */
proto.primus.Condition.prototype.getShell = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.primus.Condition} returns this
 */
proto.primus.Condition.prototype.setShell = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    mob_id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    movement: jspb.Message.getFieldWithDefault(msg, 2, 0),
    use: jspb.Message.getFieldWithDefault(msg, 3, 0),
    target_id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    command: jspb.Message.getFieldWithDefault(msg, 5, ""),
    equip_id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    discard_id: jspb.Message.getFieldWithDefault(msg, 7, ""),
    remove: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Action}
 */
proto.primus.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Action;
  return proto.primus.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Action}
 */
proto.primus.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    case 2:
      var value = /** @type {!proto.primus.Direction} */ (reader.readEnum());
      msg.setMovement(value);
      break;
    case 3:
      var value = /** @type {!proto.primus.Slot} */ (reader.readEnum());
      msg.setUse(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommand(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscardId(value);
      break;
    case 8:
      var value = /** @type {!proto.primus.Slot} */ (reader.readEnum());
      msg.setRemove(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMovement();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUse();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTargetId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCommand();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEquipId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDiscardId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRemove();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string mob_id = 1;
 * @return {string}
 */
proto.primus.Action.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Action} returns this
 */
proto.primus.Action.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Direction movement = 2;
 * @return {!proto.primus.Direction}
 */
proto.primus.Action.prototype.getMovement = function() {
  return /** @type {!proto.primus.Direction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.primus.Direction} value
 * @return {!proto.primus.Action} returns this
 */
proto.primus.Action.prototype.setMovement = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Slot use = 3;
 * @return {!proto.primus.Slot}
 */
proto.primus.Action.prototype.getUse = function() {
  return /** @type {!proto.primus.Slot} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.primus.Slot} value
 * @return {!proto.primus.Action} returns this
 */
proto.primus.Action.prototype.setUse = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string target_id = 4;
 * @return {string}
 */
proto.primus.Action.prototype.getTargetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Action} returns this
 */
proto.primus.Action.prototype.setTargetId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string command = 5;
 * @return {string}
 */
proto.primus.Action.prototype.getCommand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Action} returns this
 */
proto.primus.Action.prototype.setCommand = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string equip_id = 6;
 * @return {string}
 */
proto.primus.Action.prototype.getEquipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Action} returns this
 */
proto.primus.Action.prototype.setEquipId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string discard_id = 7;
 * @return {string}
 */
proto.primus.Action.prototype.getDiscardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Action} returns this
 */
proto.primus.Action.prototype.setDiscardId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Slot remove = 8;
 * @return {!proto.primus.Slot}
 */
proto.primus.Action.prototype.getRemove = function() {
  return /** @type {!proto.primus.Slot} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.primus.Slot} value
 * @return {!proto.primus.Action} returns this
 */
proto.primus.Action.prototype.setRemove = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.primus.Attributes.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Attributes.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Attributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Attributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Attributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    hit_points: jspb.Message.getFieldWithDefault(msg, 1, 0),
    health: (f = msg.getHealth()) && proto.primus.Attribute.toObject(includeInstance, f),
    evasion: (f = msg.getEvasion()) && proto.primus.Attribute.toObject(includeInstance, f),
    accuracies: jspb.Message.toObjectList(msg.getAccuraciesList(),
    proto.primus.Accuracy.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Attributes}
 */
proto.primus.Attributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Attributes;
  return proto.primus.Attributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Attributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Attributes}
 */
proto.primus.Attributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHitPoints(value);
      break;
    case 2:
      var value = new proto.primus.Attribute;
      reader.readMessage(value,proto.primus.Attribute.deserializeBinaryFromReader);
      msg.setHealth(value);
      break;
    case 3:
      var value = new proto.primus.Attribute;
      reader.readMessage(value,proto.primus.Attribute.deserializeBinaryFromReader);
      msg.setEvasion(value);
      break;
    case 4:
      var value = new proto.primus.Accuracy;
      reader.readMessage(value,proto.primus.Accuracy.deserializeBinaryFromReader);
      msg.addAccuracies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Attributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Attributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Attributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Attributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHitPoints();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHealth();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.primus.Attribute.serializeBinaryToWriter
    );
  }
  f = message.getEvasion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.primus.Attribute.serializeBinaryToWriter
    );
  }
  f = message.getAccuraciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.primus.Accuracy.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 hit_points = 1;
 * @return {number}
 */
proto.primus.Attributes.prototype.getHitPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Attributes} returns this
 */
proto.primus.Attributes.prototype.setHitPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Attribute health = 2;
 * @return {?proto.primus.Attribute}
 */
proto.primus.Attributes.prototype.getHealth = function() {
  return /** @type{?proto.primus.Attribute} */ (
    jspb.Message.getWrapperField(this, proto.primus.Attribute, 2));
};


/**
 * @param {?proto.primus.Attribute|undefined} value
 * @return {!proto.primus.Attributes} returns this
*/
proto.primus.Attributes.prototype.setHealth = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Attributes} returns this
 */
proto.primus.Attributes.prototype.clearHealth = function() {
  return this.setHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Attributes.prototype.hasHealth = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Attribute evasion = 3;
 * @return {?proto.primus.Attribute}
 */
proto.primus.Attributes.prototype.getEvasion = function() {
  return /** @type{?proto.primus.Attribute} */ (
    jspb.Message.getWrapperField(this, proto.primus.Attribute, 3));
};


/**
 * @param {?proto.primus.Attribute|undefined} value
 * @return {!proto.primus.Attributes} returns this
*/
proto.primus.Attributes.prototype.setEvasion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Attributes} returns this
 */
proto.primus.Attributes.prototype.clearEvasion = function() {
  return this.setEvasion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Attributes.prototype.hasEvasion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Accuracy accuracies = 4;
 * @return {!Array<!proto.primus.Accuracy>}
 */
proto.primus.Attributes.prototype.getAccuraciesList = function() {
  return /** @type{!Array<!proto.primus.Accuracy>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Accuracy, 4));
};


/**
 * @param {!Array<!proto.primus.Accuracy>} value
 * @return {!proto.primus.Attributes} returns this
*/
proto.primus.Attributes.prototype.setAccuraciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.primus.Accuracy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Accuracy}
 */
proto.primus.Attributes.prototype.addAccuracies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.primus.Accuracy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Attributes} returns this
 */
proto.primus.Attributes.prototype.clearAccuraciesList = function() {
  return this.setAccuraciesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Attribute.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Attribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Attribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Attribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    experience: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Attribute}
 */
proto.primus.Attribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Attribute;
  return proto.primus.Attribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Attribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Attribute}
 */
proto.primus.Attribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExperience(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Attribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Attribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Attribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Attribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExperience();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 value = 1;
 * @return {number}
 */
proto.primus.Attribute.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Attribute} returns this
 */
proto.primus.Attribute.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 experience = 2;
 * @return {number}
 */
proto.primus.Attribute.prototype.getExperience = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Attribute} returns this
 */
proto.primus.Attribute.prototype.setExperience = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Accuracy.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Accuracy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Accuracy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Accuracy.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    attr: (f = msg.getAttr()) && proto.primus.Attribute.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Accuracy}
 */
proto.primus.Accuracy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Accuracy;
  return proto.primus.Accuracy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Accuracy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Accuracy}
 */
proto.primus.Accuracy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.primus.Attribute;
      reader.readMessage(value,proto.primus.Attribute.deserializeBinaryFromReader);
      msg.setAttr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Accuracy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Accuracy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Accuracy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Accuracy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAttr();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.primus.Attribute.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.primus.Accuracy.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Accuracy} returns this
 */
proto.primus.Accuracy.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Attribute attr = 2;
 * @return {?proto.primus.Attribute}
 */
proto.primus.Accuracy.prototype.getAttr = function() {
  return /** @type{?proto.primus.Attribute} */ (
    jspb.Message.getWrapperField(this, proto.primus.Attribute, 2));
};


/**
 * @param {?proto.primus.Attribute|undefined} value
 * @return {!proto.primus.Accuracy} returns this
*/
proto.primus.Accuracy.prototype.setAttr = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.primus.Accuracy} returns this
 */
proto.primus.Accuracy.prototype.clearAttr = function() {
  return this.setAttr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.primus.Accuracy.prototype.hasAttr = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.primus.Item.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    slot: jspb.Message.getFieldWithDefault(msg, 4, 0),
    range: jspb.Message.getFieldWithDefault(msg, 5, 0),
    damage_min: jspb.Message.getFieldWithDefault(msg, 6, 0),
    damage_max: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cooldown: jspb.Message.getFieldWithDefault(msg, 8, 0),
    activeList: jspb.Message.toObjectList(msg.getActiveList(),
    proto.primus.Impact.toObject, includeInstance),
    passiveList: jspb.Message.toObjectList(msg.getPassiveList(),
    proto.primus.Impact.toObject, includeInstance),
    damage: jspb.Message.getFieldWithDefault(msg, 12, 0),
    durability: jspb.Message.getFieldWithDefault(msg, 13, 0),
    sprite_asset: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Item}
 */
proto.primus.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Item;
  return proto.primus.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Item}
 */
proto.primus.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.primus.Slot} */ (reader.readEnum());
      msg.setSlot(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRange(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDamageMin(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDamageMax(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCooldown(value);
      break;
    case 9:
      var value = new proto.primus.Impact;
      reader.readMessage(value,proto.primus.Impact.deserializeBinaryFromReader);
      msg.addActive(value);
      break;
    case 10:
      var value = new proto.primus.Impact;
      reader.readMessage(value,proto.primus.Impact.deserializeBinaryFromReader);
      msg.addPassive(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDamage(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurability(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpriteAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSlot();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRange();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDamageMin();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDamageMax();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCooldown();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getActiveList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.primus.Impact.serializeBinaryToWriter
    );
  }
  f = message.getPassiveList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.primus.Impact.serializeBinaryToWriter
    );
  }
  f = message.getDamage();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getDurability();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getSpriteAsset();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.primus.Item.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.primus.Item.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.primus.Item.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Slot slot = 4;
 * @return {!proto.primus.Slot}
 */
proto.primus.Item.prototype.getSlot = function() {
  return /** @type {!proto.primus.Slot} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.primus.Slot} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setSlot = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 range = 5;
 * @return {number}
 */
proto.primus.Item.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setRange = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 damage_min = 6;
 * @return {number}
 */
proto.primus.Item.prototype.getDamageMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setDamageMin = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 damage_max = 7;
 * @return {number}
 */
proto.primus.Item.prototype.getDamageMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setDamageMax = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 cooldown = 8;
 * @return {number}
 */
proto.primus.Item.prototype.getCooldown = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setCooldown = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated Impact active = 9;
 * @return {!Array<!proto.primus.Impact>}
 */
proto.primus.Item.prototype.getActiveList = function() {
  return /** @type{!Array<!proto.primus.Impact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Impact, 9));
};


/**
 * @param {!Array<!proto.primus.Impact>} value
 * @return {!proto.primus.Item} returns this
*/
proto.primus.Item.prototype.setActiveList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.primus.Impact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Impact}
 */
proto.primus.Item.prototype.addActive = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.primus.Impact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.clearActiveList = function() {
  return this.setActiveList([]);
};


/**
 * repeated Impact passive = 10;
 * @return {!Array<!proto.primus.Impact>}
 */
proto.primus.Item.prototype.getPassiveList = function() {
  return /** @type{!Array<!proto.primus.Impact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.primus.Impact, 10));
};


/**
 * @param {!Array<!proto.primus.Impact>} value
 * @return {!proto.primus.Item} returns this
*/
proto.primus.Item.prototype.setPassiveList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.primus.Impact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.primus.Impact}
 */
proto.primus.Item.prototype.addPassive = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.primus.Impact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.clearPassiveList = function() {
  return this.setPassiveList([]);
};


/**
 * optional int32 damage = 12;
 * @return {number}
 */
proto.primus.Item.prototype.getDamage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setDamage = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 durability = 13;
 * @return {number}
 */
proto.primus.Item.prototype.getDurability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setDurability = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string sprite_asset = 11;
 * @return {string}
 */
proto.primus.Item.prototype.getSpriteAsset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Item} returns this
 */
proto.primus.Item.prototype.setSpriteAsset = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Impact.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Impact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Impact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Impact.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Impact}
 */
proto.primus.Impact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Impact;
  return proto.primus.Impact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Impact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Impact}
 */
proto.primus.Impact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Impact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Impact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Impact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Impact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.primus.Impact.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Impact} returns this
 */
proto.primus.Impact.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.primus.Impact.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Impact} returns this
 */
proto.primus.Impact.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 duration = 3;
 * @return {number}
 */
proto.primus.Impact.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Impact} returns this
 */
proto.primus.Impact.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.primus.Effect.prototype.toObject = function(opt_includeInstance) {
  return proto.primus.Effect.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.primus.Effect} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Effect.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    target: jspb.Message.getFieldWithDefault(msg, 2, ""),
    source_x: jspb.Message.getFieldWithDefault(msg, 3, 0),
    source_y: jspb.Message.getFieldWithDefault(msg, 4, 0),
    target_x: jspb.Message.getFieldWithDefault(msg, 5, 0),
    target_y: jspb.Message.getFieldWithDefault(msg, 6, 0),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    value: jspb.Message.getFieldWithDefault(msg, 8, 0),
    data: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.primus.Effect}
 */
proto.primus.Effect.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.primus.Effect;
  return proto.primus.Effect.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.primus.Effect} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.primus.Effect}
 */
proto.primus.Effect.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSourceX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSourceY(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetX(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetY(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.primus.Effect.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.primus.Effect.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.primus.Effect} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.primus.Effect.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSourceX();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSourceY();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTargetX();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTargetY();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.primus.Effect.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Effect} returns this
 */
proto.primus.Effect.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target = 2;
 * @return {string}
 */
proto.primus.Effect.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Effect} returns this
 */
proto.primus.Effect.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 source_x = 3;
 * @return {number}
 */
proto.primus.Effect.prototype.getSourceX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Effect} returns this
 */
proto.primus.Effect.prototype.setSourceX = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 source_y = 4;
 * @return {number}
 */
proto.primus.Effect.prototype.getSourceY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Effect} returns this
 */
proto.primus.Effect.prototype.setSourceY = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 target_x = 5;
 * @return {number}
 */
proto.primus.Effect.prototype.getTargetX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Effect} returns this
 */
proto.primus.Effect.prototype.setTargetX = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 target_y = 6;
 * @return {number}
 */
proto.primus.Effect.prototype.getTargetY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Effect} returns this
 */
proto.primus.Effect.prototype.setTargetY = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.primus.Effect.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Effect} returns this
 */
proto.primus.Effect.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 value = 8;
 * @return {number}
 */
proto.primus.Effect.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.primus.Effect} returns this
 */
proto.primus.Effect.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string data = 9;
 * @return {string}
 */
proto.primus.Effect.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.primus.Effect} returns this
 */
proto.primus.Effect.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.primus.MobType = {
  MOB_NONE: 0,
  MOB_PRIMUS: 1,
  MOB_COMPANION: 2,
  MOB_NPC: 3,
  MOB_OBJECT: 4
};

/**
 * @enum {number}
 */
proto.primus.Direction = {
  DIR_NONE: 0,
  DIR_NORTH: 1,
  DIR_EAST: 2,
  DIR_SOUTH: 3,
  DIR_WEST: 4
};

/**
 * @enum {number}
 */
proto.primus.Slot = {
  SLOT_NONE: 0,
  SLOT_HEAD: 1,
  SLOT_ARMS: 2,
  SLOT_BODY: 3,
  SLOT_LEGS: 4,
  SLOT_INTERACT: 5
};

goog.object.extend(exports, proto.primus);
