import React from 'react';

function StatsView({ mob }) {
	return (
		<table>
			<thead>
				<tr>
					<th>Stat</th>
					<th>Value</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Hit Points</td>
					<td>{mob?.attrs?.hit_points} / {mob?.attrs?.health?.value}</td>
				</tr>
				<tr>
					<td>Armor Points</td>
					<td>0</td>
				</tr>
				<tr>
					<td>Evasion</td>
					<td>{mob?.attrs?.evasion?.value}</td>
				</tr>
			</tbody>
		</table>
	);
}

export default StatsView;
