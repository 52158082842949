import Phaser from 'phaser';

export default class LoadingScene extends Phaser.Scene {
	constructor() {
		super('Loading');
	}

	create() {
		this.add.text(100, 100, "loading...");
	}
}
