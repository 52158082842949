import React, { useState, useEffect } from 'react';

const slotLabels = ["", "Head", "Arms", "Body", "Legs"];

function InventoryItem({ item, mob, game }) {
	return (
		<tr>
			<td>{ item.name }</td>
			<td>{ slotLabels[item.slot] }</td>
			<td>
				<button onClick={()=>{
					game.scene.keys.GameScene.equipItem(mob, item)
				}} className="items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-blue-600 hover:bg-blue-500 h-10 px-4 py-2 w-full">
					equip
				</button>
			</td>
			<td>
				<button onClick={()=>{
					game.scene.keys.GameScene.discardItem(mob, item)
				}} className="items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-blue-600 hover:bg-blue-500 h-10 px-4 py-2 w-full">
					discard
				</button>
			</td>
		</tr>
	)
}

function InventoryView({ mob, game }) {
	const [ invList, setInvList ] = useState([]);

	useEffect(() => {
		var il = [];
		mob?.inventory.forEach((item) => {
			item.count = il.length;
			il.push(item);
		})
		setInvList(il);
	}, [mob]);


	return (
		<div>
			<h4>Inventory</h4>
			<table>
				<tbody>
					{invList.map((item) => <InventoryItem key={`${item.id}_${item.count}`} item={item} mob={mob} game={game} />)}
				</tbody>
			</table>
		</div>
	);
}

export default InventoryView;
