import Phaser from 'phaser';

export default class CommandInput extends Phaser.Scene {
	gs;

	text;
	textLabel;

	constructor() {
		super('CommandInput');
	}
	create() {
		this.textLabel = this.add.text(10, 575, "> _", {
			fontSize: 20,
			color: 'white',
			stroke: 'black',
			strokeThickness: 2,
		});

		this.gs = this.game.scene.keys.GameScene;

		this.text = "";

		this.input.keyboard.on('keydown', event => {
			if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.ESC) {
				this.scene.stop();
				this.gs.closeConsole();
				return;
			}

			if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.ENTER) {
				if (this.text.length > 0) {
					this.gs.sendCommand(this.text);
				}
				this.scene.stop();
				this.gs.closeConsole();
				return
			}

			if (event.keyCode === Phaser.Input.Keyboard.KeyCodes.BACKSPACE) {
				this.text = this.text.slice(0, -1);
			} else if (event.key.length === 1) {
				this.text += event.key;
			}
			this.textLabel.text = `> ${this.text}_`;
		})
	}

	clickWrite(id) {
		if (this.text.length !== 0 && this.text.slice(-1) !== " ") {
			this.text += " ";
		}
		this.text += id + " ";
		this.textLabel.text = `> ${this.text}_`;
	}

	update(t, d) {

	}
}
