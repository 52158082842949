import React from 'react';

function TabButton({ displayText, onClick, isActive }) {
	return (
		<button 
			className={isActive ? 'active' : ''}
			onClick={onClick}
		>
			{displayText}
		</button>
	);
}

export default TabButton;
