import { loadPyodide } from 'pyodide';
import { getPyodide, getThonkingWheel } from '../../constants';

var loadPromise = null;

function loadPyodideWithThonking() {
	if (loadPromise == null) {
		loadPromise = loadPyodideWithThonkingAsync();
	}
	return loadPromise;
}

async function loadPyodideWithThonkingAsync() {
	var p = await loadPyodide({
		indexURL: getPyodide("town")
	})
	await p.loadPackage("micropip");

	const micropip = p.pyimport("micropip");
	// We append ?<rand> every time to force a fresh load of the library.
	const wheelURL = `${getThonkingWheel("town")}?${Math.random()}`
	await micropip.install(wheelURL);
	console.log("Installed thonking wheel");
	return p;
}

export { loadPyodideWithThonking }
