
const DEFAULT_FRAME_RATE = 10;

export default class SpriteLoader {
	configs;

	constructor(load, cache, anims) {
		this.load = load;
		this.cache = cache;
		this.anims = anims;
		this.configs = {};
		this._loadOnceCompleteKeys = {};

		
		this.load.on(`complete`, () => {
			console.log(`load complete`);
		})
		this.load.on(`filecomplete`, (alias, type) => {
			console.log(`filecomplete ${type}: ${alias}`);
		})
		this.load.on(`addfile`, (alias, type) => {
			console.log(`addfile ${type}: ${alias}`);
		})
	}

	_loadOnceComplete(eventType, fileKey) {
		if (!this.load.isLoading()) {
			this.load.start();
		}
		return new Promise((resolve, reject) => {
			this.load.once(`filecomplete-${eventType}-${fileKey}`, (key, type, data) => {
				resolve(data);
			});
		});
	}

	async fromConfig(alias) {
		if (alias === "") {
			console.error(`using empty string for sprite alias`);
			return;
		}

		if (this.cache.json.entries.has(alias)) {
			return alias;
		}

		// A bad path is getting in here, unsure how. But we want
		// it to be blank or it screws up the download URL.
		this.load.path = "";
		this.load.json(alias, `assets/sprites/${alias}.json`);
		this.configs[alias] = await this._loadOnceComplete('json', alias);

		await this._loadSpriteSheet(alias);

		return alias;
	}

	getConfig(alias) {
		return this.configs[alias];
	}

	add(scene, alias) {
		const spr = scene.add.sprite(0, 0, alias);
		spr.config = this.configs[alias];
		spr.alias = alias;
		return spr;
	}

	async _loadSpriteSheet(alias) {
		const spriteConfig = this.configs[alias];

		// A bad path is getting in here, unsure how. But we want
		// it to be blank or it screws up the download URL.
		this.load.path = "";
		this.load.spritesheet(alias,
			`assets/sheets/${spriteConfig.sheet}`,
			{
				frameWidth: spriteConfig.pixels * spriteConfig.width,
				frameHeight: spriteConfig.pixels * spriteConfig.height
			}
		);
		await this._loadOnceComplete("spritesheet", alias);

		// The loader takes care of smoothly joining duplicate requests with its
		// cache. However, the animations are done without fetching - they simply
		// chop up resources already loaded. So, for each group we check if one of
		// the animations exists before creating the others. The downside is not
		// severe - we get warnings in the dev console, but it's nice to have that
		// clear for development.

		if (spriteConfig.frameRate === undefined) {
			spriteConfig.frameRate = DEFAULT_FRAME_RATE;
		}

		if (spriteConfig.pulsing !== undefined) {
			if (`${alias}:pulse` in this.anims.anims.entries) {
				return;
			}

			const o = spriteConfig.pulsing;
			this.anims.create({
				key: `${alias}:pulse`,
				frames: this.anims.generateFrameNumbers(alias, { start: 0+o, end: 3+o }),
				frameRate: spriteConfig.frameRate,
				repeat: -1
			});
		}

		if (spriteConfig.walking !== undefined) {
			if (`${alias}:south` in this.anims.anims.entries) {
				return;
			}

			const o = spriteConfig.walking;
			this.anims.create({
				key: `${alias}:south`,
				frames: this.anims.generateFrameNumbers(alias, { start: 0+o, end: 3+o }),
				frameRate: spriteConfig.frameRate,
				repeat: -1
			});
			this.anims.create({
				key: `${alias}:stand-south`,
				frames: this.anims.generateFrameNumbers(alias, { start: 0+o, end: 0+o }),
				frameRate: spriteConfig.frameRate,
				repeat: -1
			});
			this.anims.create({
				key: `${alias}:west`,
				frames: this.anims.generateFrameNumbers(alias, { start: 4+o, end: 7+o }),
				frameRate: spriteConfig.frameRate,
				repeat: -1
			});
			this.anims.create({
				key: `${alias}:stand-west`,
				frames: this.anims.generateFrameNumbers(alias, { start: 4+o, end: 4+o }),
				frameRate: spriteConfig.frameRate,
				repeat: -1
			});
			this.anims.create({
				key: `${alias}:east`,
				frames: this.anims.generateFrameNumbers(alias, { start: 8+o, end: 11+o }),
				frameRate: spriteConfig.frameRate,
				repeat: -1
			});
			this.anims.create({
				key: `${alias}:stand-east`,
				frames: this.anims.generateFrameNumbers(alias, { start: 8+o, end: 8+o }),
				frameRate: spriteConfig.frameRate,
				repeat: -1
			});
			this.anims.create({
				key: `${alias}:north`,
				frames: this.anims.generateFrameNumbers(alias, { start: 12+o, end: 15+o }),
				frameRate: spriteConfig.frameRate,
				repeat: -1
			});
			this.anims.create({
				key: `${alias}:stand-north`,
				frames: this.anims.generateFrameNumbers(alias, { start: 12+o, end: 12+o }),
				frameRate: spriteConfig.frameRate,
				repeat: -1
			});
		}
	}
};
