import React from 'react';
import './App.css';
import Main from './Main.js';

function App() {
	
	document.title = "primus"
	return (
		<div className="App">
			<Main/>
		</div>
	);
}

export default App;
