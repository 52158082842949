import React, { useEffect } from 'react';
import './Landing.css';
import Header from './Header';
import PlayerPicker from './PlayerPicker';
import Footer from './Footer';
import InfoPanel from './InfoPanel';
import User from './User';

function Landing({auth, user, setUser, playerID, setPlayerID, clearGame}) {
	if (user === undefined) {
		return "loading";
	}
	//  .header, .microblog, .player-picker, .links, .create-player, .footer
	document.title = "primus"
	return (
		<div className="min-vh-100 grid grid-rows-[75px_1fr_100px]">
			<div>
				<Header className="header" auth={auth} user={user} setUser={setUser} />
			</div>
			<div className="flex max-w-full">
				<aside className="w-vw33 px-8 py-6 space-y-6 overflow-y-auto">
					<InfoPanel />
				</aside>
				<main className="w-vw67 px-8 py-6">
					<div>
						<User auth={auth} user={user} setUser={setUser} />
					</div>
					<div>
						<PlayerPicker user={user} playerID={playerID} setPlayerID={setPlayerID}/>
					</div>
				</main>
			</div>
			<div className="footer">
				<Footer />
			</div>
		</div>
	);
}

export default Landing;
