import Phaser from 'phaser';
import { getAresHost } from '../../constants';

export default class DevHUD extends Phaser.Scene {
	gs;

	xylabel;
	fpslabel;
	tilelabel;
	pinglabel;
	thonklabel;

	lastpingAt;

	constructor() {
		super('DevHUD');
	}
	create() {
		this.add.text(0, 0, "devmode");
		this.fpslabel = this.add.text(100, 0, "");
		this.xylabel = this.add.text(200, 0, "");
		this.pinglabel = this.add.text(300, 0, "");
		this.thonklabel = this.add.text(400, 0, "");

		this.gs = this.game.scene.keys.GameScene;
		this.nextPingAt = 0;
	}

	update(t, d) {
		this.fpslabel.text = `${Math.trunc(0.5 + (1000 / d))}fps`;

		const fm = this.gs.mobs.focus;
		if (fm != null) {
			this.xylabel.text = `${fm.x},${fm.y}`;
		} else {
			this.xylabel.text = '-';
		}

		if (Date.now() > this.nextPingAt) {
			const host = getAresHost("town");
			const start = Date.now();
			fetch(`${host}/ping`).then(() => {
				const dur = Date.now() - start;
				this.pinglabel.text = `ping:${dur}ms`;
				this.nextPingAt = Date.now() + 500;
			}).catch((e) => {
				console.log(`no ping: ${e}`);
			})
			this.nextPingAt = Date.now() + 5000;
		}

		if (this.gs.thonkDuration !== undefined) {
			this.thonklabel.text = `thonk:${this.gs.thonkDuration}ms`;
		}
	}
}
